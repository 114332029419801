import { useState } from "react";
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Input, InputGroup, InputGroupText, Row } from "reactstrap";
import { removeAnswerDB, updateSurveyAnswer } from "../../data/data";

function Answer(props) {
  const [answer, setAnswer] = useState(props.answer);
  const [answerTitle, setAnswerTitle] = useState(props.answer.answer_title);
  const [answerValue, setAnswerValue] = useState(props.answer.answer_value);
  const [answerImgId, setAnswerImgId] = useState(props.answer.answer_img_id);
  const [answerBtnType, setAnswerBtnType] = useState(props.answer.answer_button_type);
  const [answerId, setAnswerId] = useState(props.answer.id);
  const [answerOrder, setAnswerOrder] = useState(props.answer.answer_order || 0);

  async function updateAns() {
    if (!window.confirm("답변을 저장 하시겠습니까?")) {
      return;
    }
    let ansInfo = {
      id: answerId,
      answer_title: answerTitle,
      answer_value: answerValue,
      answer_img_id: answerImgId,
      answer_button_type: answerBtnType,
      answer_order: answerOrder,
    }
    const res = await updateSurveyAnswer(ansInfo);
    console.log("updated qna:", res);
  }

  async function deleteAnswer() {
    if(!window.confirm("정말로 해당 답변을 삭제 하시겠습니까?")) {
      return;
    }

    let res = await removeAnswerDB(answerId);
    if(res) {
      alert("답변 삭제완료. 리프레시하시요.")
    }
  }
  return (
    <>
      <Card style={{ marginTop: "5px" }}>
        <CardHeader>
          id: {answerId}
          <center>
            <b>
              {answerTitle}
            </b>
          </center>
        </CardHeader>

        <CardBody>
          <InputGroup size="sm">
            <InputGroupText style={{ width: "45%" }}>
              order
            </InputGroupText>
            <Input type="text" placeholder="순서" value={answerOrder} onChange={(e) => {
              setAnswerOrder(e.target.value);
            }} />
          </InputGroup>
          <InputGroup size="sm">
            <InputGroupText style={{ width: "45%" }}>
              display_title
            </InputGroupText>
            <Input type="text" placeholder="display title" value={answerTitle} onChange={(e) => {
              setAnswerTitle(e.target.value);
            }} />
          </InputGroup>
          <InputGroup size="sm">
            <InputGroupText style={{ width: "45%" }}>
              value
            </InputGroupText>
            <Input type="text" placeholder="value" value={answerValue} onChange={(e) => {
              setAnswerValue(e.target.value);
            }} />
          </InputGroup>
          <InputGroup size="sm">
            <InputGroupText style={{ width: "45%" }}>
              img_id
            </InputGroupText>
            <Input type="text" placeholder="img_id" value={answerImgId} onChange={(e) => {
              setAnswerImgId(e.target.value);
            }} />
          </InputGroup>

          <InputGroup size="sm">
            <InputGroupText style={{ width: "45%" }}>
              btn_type
            </InputGroupText>
            <Input value={answerBtnType} type="select" onChange={(e) => {
              setAnswerBtnType(e.target.value);
            }}>
              <option>
                RECT
              </option>
              <option>
                SQUARE
              </option>
              <option>
                CIRCLE
              </option>
            </Input>
          </InputGroup>


        </CardBody>
        <CardFooter>
          <Row>
            <Col md="6">
              <Button size="sm" style={{ width: "100%" }} color="primary" onClick={updateAns} >답변 저장</Button>
            </Col>
            <Col md="6">
              <Button onClick={deleteAnswer} size="sm" style={{ width: "100%" }} outline >삭제하기</Button>
            </Col>
          </Row>
        </CardFooter>
      </Card>
    </>
  )
}

export default Answer
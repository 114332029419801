import { API } from "aws-amplify";
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/auth";
import getAvailTimes from "../views/SchedulerPage/getAvailTimes";

// Survey CRUD
//
export const createSurvey = async (surveyInfo) => {
  try {
    const newSurvey = await API.graphql({
      query: mutations.createSurvey,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: surveyInfo },
    });
  } catch (error) {
    console.log(error);
    alert(JSON.stringify(error));
  }
};

export const getAllSurveys = async (sortDir) => {
  const surveys = await API.graphql({
    query: queries.listSurveys,
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
  });

  if (surveys.data) {
    return surveys.data.listSurveys.items;
  }
};

// QNA CRUD
//

/* create qna */
export const createSurveyQna = async (qnaInfo) => {
  try {
    const newSurvey = await API.graphql({
      query: mutations.createSurveyQNA,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: qnaInfo },
    });
    return newSurvey;
  } catch (error) {
    console.log(error);
    alert(JSON.stringify(error));
  }
};

// update qna *//
export const updateSurveyQna = async (qnaInfo) => {
  try {
    const updatedSurvey = await API.graphql({
      query: mutations.updateSurveyQNA,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: qnaInfo },
    });
    return updatedSurvey;
  } catch (error) {
    console.log(error);
  }
}

// ANSWER CRUD
//

/* create qna */
export const createSurveyAnswer = async (ansInfo) => {
  try {
    const newAnswer = await API.graphql({
      query: mutations.createSurveyAnswer,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: ansInfo },
    });
    return newAnswer;
  } catch (error) {
    console.log(error);
    alert(JSON.stringify(error));
  }
};

export const updateSurveyAnswer = async (ansInfo) => {
  try {
    const updatedAnswer = await API.graphql({
      query: mutations.updateSurveyAnswer,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: ansInfo },
    });
    return updatedAnswer;
  } catch (error) {
    console.log(error);
  }
}

// Medicine CRUD
//
// Create Medince
export const createMedicineInfo = async (medicineInfo) => {
  try {
    const newMedicine = await API.graphql({
      query: mutations.createMedicineInfo,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: medicineInfo },
    });
    return newMedicine;
  } catch (error) {
    console.log(error);
    alert(JSON.stringify(error));
  }
};

export const updateMedicineInfo = async (medicineInfo) => {
  try {
    const updatedMedicineInfo = await API.graphql({
      query: mutations.updateMedicineInfo,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: medicineInfo },
    });
    return updatedMedicineInfo;
  } catch (error) {
    console.log(error);
  }
}

export const getAllMedicineInfos = async (sortDir) => {
  const medicines = await API.graphql({
    query: queries.listMedicineInfos,
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
  });

  if (medicines.data) {
    return medicines.data.listMedicineInfos.items;
  }
};

//  MARS UTILS
//
export const getMarsUtils = async (id) => {
  try {
    const newUtils = await API.graphql({
      query: queries.getMarsUtil,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { id: id },
    });
    if (newUtils) {
      return newUtils;
    }
  } catch (error) {
    console.log(error);
  }
};

export const insertMarsUtils = async (utilInfo) => {
  try {
    const newUtils = await API.graphql({
      query: mutations.createMarsUtil,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: utilInfo },
    });
    if (newUtils) {
      return newUtils;
    }
  } catch (error) {
    console.log(error);
  }
};
export const updateMarsUtils = async (utilInfo) => {
  try {
    const newUtils = await API.graphql({
      query: mutations.updateMarsUtil,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: utilInfo },
    });
    if (newUtils) {
      return newUtils;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getScheduleFromServer = async () => {
  // fetch doctor scheduels from server:
  const res = await getMarsUtils("mars_doctor_schedules");

  let data;
  if (res) {
    data = res.data.getMarsUtil;
    let doctorSchedules = JSON.parse(data.value);

    let doctor1TimeArray = doctorSchedules.doctor1;
    let doctor2TimeArray = doctorSchedules.doctor2;
    let gaonTimeArray = doctorSchedules.gaonsamsung;

    // get compiled schedule
    let compiledTimeSlots = [];

    for (let i = 0; i < doctor1TimeArray.length; i++) {
      // console.log(doctor1TimeArray[i].time, doctor2TimeArray[i].time, gaonTimeArray[i].time);

      let avail = false;
      if (
        doctor1TimeArray[i].isAvail ||
        doctor2TimeArray[i].isAvail ||
        gaonTimeArray[i].isAvail
      ) {
        avail = true;
      }

      compiledTimeSlots.push({
        time: doctor1TimeArray[i].time,
        isAvail: avail,
      });
    }

    // get avail times using compiled timeslot
    let availTimes = getAvailTimes(compiledTimeSlots);
    return availTimes;
  } else {
    // slack2accept("스케쥴러 에러발생!! 김선민을 찾아라!!");
    console.log("스케쥴러 에러발생 김선민을 찾아라!");
  }
};

export const removeAnswerDB = async (id) => {
  try {
    const deletedAnswer = await API.graphql({
      query: mutations.deleteSurveyAnswer,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: { id: id } },
    });
    if (deletedAnswer) {
      return deletedAnswer;
    }
  } catch (error) {
    console.log(error);
  }
};

export const removeQnaDB = async (id) => {
  try {
    const deletedAnswer = await API.graphql({
      query: mutations.deleteSurveyQNA,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: { id: id } },
    });
    if (deletedAnswer) {
      return deletedAnswer;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAllProductInfoDB = async () => {
  try {
    const products = await API.graphql({
      query: queries.listProductInfos,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
    });

    if (products.data) {
      return products.data.listProductInfos.items;
    }
  } catch (error) {
    console.log(error);
  }
}

export const createProductInfoDB = async (info) => {
  try {
    const newProduct = await API.graphql({
      query: mutations.createProductInfo,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: info },
    });
    return newProduct;
  } catch (error) {
    console.log(error);
    alert(JSON.stringify(error));
  }
}

export const updateProductInfoDB = async (info) => {
  try {
    const updatedProductInfo = await API.graphql({
      query: mutations.updateProductInfo,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: info },
    });
    return updatedProductInfo;
  } catch (error) {
    console.log(error);
  }
}
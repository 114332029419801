export const zeroPad = (num, places) => String(num).padStart(places, "0");

export const formatDisplayTime = (time) => {
  let timeObj = new Date(time);
  let month = timeObj.getMonth() + 1;
  let date = timeObj.getDate();
  let hour = timeObj.getHours();

  return `${zeroPad(hour, 2)}:00 - ${zeroPad(hour + 1, 2)}:00`;
};

export const formatDisplayDate = (time) => {
  let yoil = ["일", "월", "화", "수", "목", "금", "토"];

  let timeObj = new Date(time);
  let month = timeObj.getMonth() + 1;
  let date = timeObj.getDate();
  let day = timeObj.getDay();
  let hour = timeObj.getHours();

  return `${zeroPad(month, 2)}월${zeroPad(date, 2)}일 ${yoil[day]}요일`;
};
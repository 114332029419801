/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPhotoEvent = /* GraphQL */ `
  mutation CreatePhotoEvent(
    $input: CreatePhotoEventInput!
    $condition: ModelPhotoEventConditionInput
  ) {
    createPhotoEvent(input: $input, condition: $condition) {
      id
      name
      phone
      birth
      center
      front
      addr
      addr2
      zip
      createdAt
      updatedAt
    }
  }
`;
export const updatePhotoEvent = /* GraphQL */ `
  mutation UpdatePhotoEvent(
    $input: UpdatePhotoEventInput!
    $condition: ModelPhotoEventConditionInput
  ) {
    updatePhotoEvent(input: $input, condition: $condition) {
      id
      name
      phone
      birth
      center
      front
      addr
      addr2
      zip
      createdAt
      updatedAt
    }
  }
`;
export const deletePhotoEvent = /* GraphQL */ `
  mutation DeletePhotoEvent(
    $input: DeletePhotoEventInput!
    $condition: ModelPhotoEventConditionInput
  ) {
    deletePhotoEvent(input: $input, condition: $condition) {
      id
      name
      phone
      birth
      center
      front
      addr
      addr2
      zip
      createdAt
      updatedAt
    }
  }
`;
export const createMars = /* GraphQL */ `
  mutation CreateMars(
    $input: CreateMarsInput!
    $condition: ModelMarsConditionInput
  ) {
    createMars(input: $input, condition: $condition) {
      id
      cid
      type
      kid
      createdAt
      updatedAt
      name
      phone
      email
      ssn
      addr
      addr2
      zip
      charts
      cards
      ans
      status
      dstatus
      pstatus
      p2status
      when
      filekey
      delivery_number
      payResult
      isPaid
      nesh_id
      pill
      minox
      shamp
      shamp_id
      month
      minoxMonth
      pillMonth
      total
      survey_type
      survey_version
      prescription
      ignoreStatus
      reservation_time
      yeyak_time
      hairline_url
      haircenter_url
      hairgeneral_url
      doctor
      rm
      r
      capil
      capilMonth
      medi_done_time
      pharma
      extraInfo
      deliMsg
      prescription_update_time
    }
  }
`;
export const updateMars = /* GraphQL */ `
  mutation UpdateMars(
    $input: UpdateMarsInput!
    $condition: ModelMarsConditionInput
  ) {
    updateMars(input: $input, condition: $condition) {
      id
      cid
      type
      kid
      createdAt
      updatedAt
      name
      phone
      email
      ssn
      addr
      addr2
      zip
      charts
      cards
      ans
      status
      dstatus
      pstatus
      p2status
      when
      filekey
      delivery_number
      payResult
      isPaid
      nesh_id
      pill
      minox
      shamp
      shamp_id
      month
      minoxMonth
      pillMonth
      total
      survey_type
      survey_version
      prescription
      ignoreStatus
      reservation_time
      yeyak_time
      hairline_url
      haircenter_url
      hairgeneral_url
      doctor
      rm
      r
      capil
      capilMonth
      medi_done_time
      pharma
      extraInfo
      deliMsg
      prescription_update_time
    }
  }
`;
export const deleteMars = /* GraphQL */ `
  mutation DeleteMars(
    $input: DeleteMarsInput!
    $condition: ModelMarsConditionInput
  ) {
    deleteMars(input: $input, condition: $condition) {
      id
      cid
      type
      kid
      createdAt
      updatedAt
      name
      phone
      email
      ssn
      addr
      addr2
      zip
      charts
      cards
      ans
      status
      dstatus
      pstatus
      p2status
      when
      filekey
      delivery_number
      payResult
      isPaid
      nesh_id
      pill
      minox
      shamp
      shamp_id
      month
      minoxMonth
      pillMonth
      total
      survey_type
      survey_version
      prescription
      ignoreStatus
      reservation_time
      yeyak_time
      hairline_url
      haircenter_url
      hairgeneral_url
      doctor
      rm
      r
      capil
      capilMonth
      medi_done_time
      pharma
      extraInfo
      deliMsg
      prescription_update_time
    }
  }
`;
export const createMarsUtil = /* GraphQL */ `
  mutation CreateMarsUtil(
    $input: CreateMarsUtilInput!
    $condition: ModelMarsUtilConditionInput
  ) {
    createMarsUtil(input: $input, condition: $condition) {
      id
      value
      createdAt
      updatedAt
    }
  }
`;
export const updateMarsUtil = /* GraphQL */ `
  mutation UpdateMarsUtil(
    $input: UpdateMarsUtilInput!
    $condition: ModelMarsUtilConditionInput
  ) {
    updateMarsUtil(input: $input, condition: $condition) {
      id
      value
      createdAt
      updatedAt
    }
  }
`;
export const deleteMarsUtil = /* GraphQL */ `
  mutation DeleteMarsUtil(
    $input: DeleteMarsUtilInput!
    $condition: ModelMarsUtilConditionInput
  ) {
    deleteMarsUtil(input: $input, condition: $condition) {
      id
      value
      createdAt
      updatedAt
    }
  }
`;
export const createMarsAnalytics = /* GraphQL */ `
  mutation CreateMarsAnalytics(
    $input: CreateMarsAnalyticsInput!
    $condition: ModelMarsAnalyticsConditionInput
  ) {
    createMarsAnalytics(input: $input, condition: $condition) {
      kid
      id
      timestamp
      action
      chart_id
      user_pill
      doctor_pill
      from
      st
      et
      duration
      exit
      addon
      question
      answer
      patient_type
      msg
      createdAt
      updatedAt
    }
  }
`;
export const updateMarsAnalytics = /* GraphQL */ `
  mutation UpdateMarsAnalytics(
    $input: UpdateMarsAnalyticsInput!
    $condition: ModelMarsAnalyticsConditionInput
  ) {
    updateMarsAnalytics(input: $input, condition: $condition) {
      kid
      id
      timestamp
      action
      chart_id
      user_pill
      doctor_pill
      from
      st
      et
      duration
      exit
      addon
      question
      answer
      patient_type
      msg
      createdAt
      updatedAt
    }
  }
`;
export const deleteMarsAnalytics = /* GraphQL */ `
  mutation DeleteMarsAnalytics(
    $input: DeleteMarsAnalyticsInput!
    $condition: ModelMarsAnalyticsConditionInput
  ) {
    deleteMarsAnalytics(input: $input, condition: $condition) {
      kid
      id
      timestamp
      action
      chart_id
      user_pill
      doctor_pill
      from
      st
      et
      duration
      exit
      addon
      question
      answer
      patient_type
      msg
      createdAt
      updatedAt
    }
  }
`;
export const createSurvey = /* GraphQL */ `
  mutation CreateSurvey(
    $input: CreateSurveyInput!
    $condition: ModelSurveyConditionInput
  ) {
    createSurvey(input: $input, condition: $condition) {
      id
      name
      survey_platform
      version
      type
      questions_answers {
        items {
          id
          question_id
          question_number
          question_title
          question_title_short
          question_subtitle
          question_type
          question_btn_type
          max_choice
          answers {
            items {
              id
              answer_button_type
              answer_title
              answer_value
              answer_img_id
              answer_img_url
              answer_order
              createdAt
              updatedAt
              surveyQNAAnswersId
            }
            nextToken
          }
          createdAt
          updatedAt
          surveyQuestions_answersId
        }
        nextToken
      }
      flow_chart
      comment
      createdAt
      updatedAt
    }
  }
`;
export const updateSurvey = /* GraphQL */ `
  mutation UpdateSurvey(
    $input: UpdateSurveyInput!
    $condition: ModelSurveyConditionInput
  ) {
    updateSurvey(input: $input, condition: $condition) {
      id
      name
      survey_platform
      version
      type
      questions_answers {
        items {
          id
          question_id
          question_number
          question_title
          question_title_short
          question_subtitle
          question_type
          question_btn_type
          max_choice
          answers {
            items {
              id
              answer_button_type
              answer_title
              answer_value
              answer_img_id
              answer_img_url
              answer_order
              createdAt
              updatedAt
              surveyQNAAnswersId
            }
            nextToken
          }
          createdAt
          updatedAt
          surveyQuestions_answersId
        }
        nextToken
      }
      flow_chart
      comment
      createdAt
      updatedAt
    }
  }
`;
export const deleteSurvey = /* GraphQL */ `
  mutation DeleteSurvey(
    $input: DeleteSurveyInput!
    $condition: ModelSurveyConditionInput
  ) {
    deleteSurvey(input: $input, condition: $condition) {
      id
      name
      survey_platform
      version
      type
      questions_answers {
        items {
          id
          question_id
          question_number
          question_title
          question_title_short
          question_subtitle
          question_type
          question_btn_type
          max_choice
          answers {
            items {
              id
              answer_button_type
              answer_title
              answer_value
              answer_img_id
              answer_img_url
              answer_order
              createdAt
              updatedAt
              surveyQNAAnswersId
            }
            nextToken
          }
          createdAt
          updatedAt
          surveyQuestions_answersId
        }
        nextToken
      }
      flow_chart
      comment
      createdAt
      updatedAt
    }
  }
`;
export const createSurveyQNA = /* GraphQL */ `
  mutation CreateSurveyQNA(
    $input: CreateSurveyQNAInput!
    $condition: ModelSurveyQNAConditionInput
  ) {
    createSurveyQNA(input: $input, condition: $condition) {
      id
      question_id
      question_number
      question_title
      question_title_short
      question_subtitle
      question_type
      question_btn_type
      max_choice
      answers {
        items {
          id
          answer_button_type
          answer_title
          answer_value
          answer_img_id
          answer_img_url
          answer_order
          createdAt
          updatedAt
          surveyQNAAnswersId
        }
        nextToken
      }
      createdAt
      updatedAt
      surveyQuestions_answersId
    }
  }
`;
export const updateSurveyQNA = /* GraphQL */ `
  mutation UpdateSurveyQNA(
    $input: UpdateSurveyQNAInput!
    $condition: ModelSurveyQNAConditionInput
  ) {
    updateSurveyQNA(input: $input, condition: $condition) {
      id
      question_id
      question_number
      question_title
      question_title_short
      question_subtitle
      question_type
      question_btn_type
      max_choice
      answers {
        items {
          id
          answer_button_type
          answer_title
          answer_value
          answer_img_id
          answer_img_url
          answer_order
          createdAt
          updatedAt
          surveyQNAAnswersId
        }
        nextToken
      }
      createdAt
      updatedAt
      surveyQuestions_answersId
    }
  }
`;
export const deleteSurveyQNA = /* GraphQL */ `
  mutation DeleteSurveyQNA(
    $input: DeleteSurveyQNAInput!
    $condition: ModelSurveyQNAConditionInput
  ) {
    deleteSurveyQNA(input: $input, condition: $condition) {
      id
      question_id
      question_number
      question_title
      question_title_short
      question_subtitle
      question_type
      question_btn_type
      max_choice
      answers {
        items {
          id
          answer_button_type
          answer_title
          answer_value
          answer_img_id
          answer_img_url
          answer_order
          createdAt
          updatedAt
          surveyQNAAnswersId
        }
        nextToken
      }
      createdAt
      updatedAt
      surveyQuestions_answersId
    }
  }
`;
export const createSurveyAnswer = /* GraphQL */ `
  mutation CreateSurveyAnswer(
    $input: CreateSurveyAnswerInput!
    $condition: ModelSurveyAnswerConditionInput
  ) {
    createSurveyAnswer(input: $input, condition: $condition) {
      id
      answer_button_type
      answer_title
      answer_value
      answer_img_id
      answer_img_url
      answer_order
      createdAt
      updatedAt
      surveyQNAAnswersId
    }
  }
`;
export const updateSurveyAnswer = /* GraphQL */ `
  mutation UpdateSurveyAnswer(
    $input: UpdateSurveyAnswerInput!
    $condition: ModelSurveyAnswerConditionInput
  ) {
    updateSurveyAnswer(input: $input, condition: $condition) {
      id
      answer_button_type
      answer_title
      answer_value
      answer_img_id
      answer_img_url
      answer_order
      createdAt
      updatedAt
      surveyQNAAnswersId
    }
  }
`;
export const deleteSurveyAnswer = /* GraphQL */ `
  mutation DeleteSurveyAnswer(
    $input: DeleteSurveyAnswerInput!
    $condition: ModelSurveyAnswerConditionInput
  ) {
    deleteSurveyAnswer(input: $input, condition: $condition) {
      id
      answer_button_type
      answer_title
      answer_value
      answer_img_id
      answer_img_url
      answer_order
      createdAt
      updatedAt
      surveyQNAAnswersId
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      user_info {
        id
        name
        age
        phone
        ssn
        addrs {
          items {
            id
            nick_name
            road_address
            detail_address
            zip
            is_default
            createdAt
            updatedAt
            userInfoAddrsId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      cards {
        items {
          id
          card_name
          card_company
          card_number
          billing_key
          last_used_time
          createdAt
          updatedAt
          userCardsId
        }
        nextToken
      }
      venus_charts {
        items {
          id
          patient_name
          user_id
          user_info {
            id
            name
            age
            phone
            ssn
            addrs {
              items {
                id
                nick_name
                road_address
                detail_address
                zip
                is_default
                createdAt
                updatedAt
                userInfoAddrsId
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          yeyak_time
          platform
          kid
          user_status
          doctor_status
          pharma_status
          survey_answers
          survey_type
          user_msg
          doctor_id
          doctor {
            id
            doctor_name
            office_name
            office_address
            office_phone
            doctor_phone
            createdAt
            updatedAt
          }
          when
          recommended_medicines {
            items {
              id
              medicine_info_id
              medicine {
                id
                type
                ingredient
                company
                product_name
                nick_name
                retail_price
                description
                unit
                base
                multiplier
                price_multiplier
                info_url
                display_order
                createdAt
                updatedAt
              }
              dosage
              period
              period_unit
              display_order
              prescriptionPrescribed_medicineId
              createdAt
              updatedAt
              chartRecommended_medicinesId
            }
            nextToken
          }
          prescription_id
          prescription {
            id
            pharma_id
            doctor_id
            doctor {
              id
              doctor_name
              office_name
              office_address
              office_phone
              doctor_phone
              createdAt
              updatedAt
            }
            s3_key
            s3_identity
            status
            prescribed_medicine {
              items {
                id
                medicine_info_id
                medicine {
                  id
                  type
                  ingredient
                  company
                  product_name
                  nick_name
                  retail_price
                  description
                  unit
                  base
                  multiplier
                  price_multiplier
                  info_url
                  display_order
                  createdAt
                  updatedAt
                }
                dosage
                period
                period_unit
                display_order
                prescriptionPrescribed_medicineId
                createdAt
                updatedAt
                chartRecommended_medicinesId
              }
              nextToken
            }
            prescribed_time
            createdAt
            updatedAt
          }
          order_set_id
          orderSet {
            id
            kid
            status
            order_shipments {
              items {
                id
                kid
                status
                brand
                processor
                chart_id
                chart {
                  id
                  patient_name
                  user_id
                  yeyak_time
                  platform
                  kid
                  user_status
                  doctor_status
                  pharma_status
                  survey_answers
                  survey_type
                  user_msg
                  doctor_id
                  when
                  prescription_id
                  order_set_id
                  createdAt
                  updatedAt
                  userVenus_chartsId
                }
                processor_status
                updatedAt
                processor_id
                prescription_id
                prescription {
                  id
                  pharma_id
                  doctor_id
                  s3_key
                  s3_identity
                  status
                  prescribed_time
                  createdAt
                  updatedAt
                }
                total
                orders {
                  nextToken
                }
                road_address
                detail_address
                zip
                phone
                name
                delivery_company
                delivery_number
                createdAt
                orderSetOrder_shipmentsId
              }
              nextToken
            }
            chart_id
            total
            createdAt
            updatedAt
            userOrder_setsId
          }
          createdAt
          updatedAt
          userVenus_chartsId
        }
        nextToken
      }
      order_sets {
        items {
          id
          kid
          status
          order_shipments {
            items {
              id
              kid
              status
              brand
              processor
              chart_id
              chart {
                id
                patient_name
                user_id
                user_info {
                  id
                  name
                  age
                  phone
                  ssn
                  createdAt
                  updatedAt
                }
                yeyak_time
                platform
                kid
                user_status
                doctor_status
                pharma_status
                survey_answers
                survey_type
                user_msg
                doctor_id
                doctor {
                  id
                  doctor_name
                  office_name
                  office_address
                  office_phone
                  doctor_phone
                  createdAt
                  updatedAt
                }
                when
                recommended_medicines {
                  nextToken
                }
                prescription_id
                prescription {
                  id
                  pharma_id
                  doctor_id
                  s3_key
                  s3_identity
                  status
                  prescribed_time
                  createdAt
                  updatedAt
                }
                order_set_id
                orderSet {
                  id
                  kid
                  status
                  chart_id
                  total
                  createdAt
                  updatedAt
                  userOrder_setsId
                }
                createdAt
                updatedAt
                userVenus_chartsId
              }
              processor_status
              updatedAt
              processor_id
              prescription_id
              prescription {
                id
                pharma_id
                doctor_id
                doctor {
                  id
                  doctor_name
                  office_name
                  office_address
                  office_phone
                  doctor_phone
                  createdAt
                  updatedAt
                }
                s3_key
                s3_identity
                status
                prescribed_medicine {
                  nextToken
                }
                prescribed_time
                createdAt
                updatedAt
              }
              total
              orders {
                items {
                  id
                  category
                  product_name
                  product_id
                  product_count
                  product_unit
                  product_base
                  product_quantity_str
                  product_price
                  order_total_price
                  createdAt
                  updatedAt
                  orderShipmentOrdersId
                }
                nextToken
              }
              road_address
              detail_address
              zip
              phone
              name
              delivery_company
              delivery_number
              createdAt
              orderSetOrder_shipmentsId
            }
            nextToken
          }
          chart_id
          total
          createdAt
          updatedAt
          userOrder_setsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      user_info {
        id
        name
        age
        phone
        ssn
        addrs {
          items {
            id
            nick_name
            road_address
            detail_address
            zip
            is_default
            createdAt
            updatedAt
            userInfoAddrsId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      cards {
        items {
          id
          card_name
          card_company
          card_number
          billing_key
          last_used_time
          createdAt
          updatedAt
          userCardsId
        }
        nextToken
      }
      venus_charts {
        items {
          id
          patient_name
          user_id
          user_info {
            id
            name
            age
            phone
            ssn
            addrs {
              items {
                id
                nick_name
                road_address
                detail_address
                zip
                is_default
                createdAt
                updatedAt
                userInfoAddrsId
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          yeyak_time
          platform
          kid
          user_status
          doctor_status
          pharma_status
          survey_answers
          survey_type
          user_msg
          doctor_id
          doctor {
            id
            doctor_name
            office_name
            office_address
            office_phone
            doctor_phone
            createdAt
            updatedAt
          }
          when
          recommended_medicines {
            items {
              id
              medicine_info_id
              medicine {
                id
                type
                ingredient
                company
                product_name
                nick_name
                retail_price
                description
                unit
                base
                multiplier
                price_multiplier
                info_url
                display_order
                createdAt
                updatedAt
              }
              dosage
              period
              period_unit
              display_order
              prescriptionPrescribed_medicineId
              createdAt
              updatedAt
              chartRecommended_medicinesId
            }
            nextToken
          }
          prescription_id
          prescription {
            id
            pharma_id
            doctor_id
            doctor {
              id
              doctor_name
              office_name
              office_address
              office_phone
              doctor_phone
              createdAt
              updatedAt
            }
            s3_key
            s3_identity
            status
            prescribed_medicine {
              items {
                id
                medicine_info_id
                medicine {
                  id
                  type
                  ingredient
                  company
                  product_name
                  nick_name
                  retail_price
                  description
                  unit
                  base
                  multiplier
                  price_multiplier
                  info_url
                  display_order
                  createdAt
                  updatedAt
                }
                dosage
                period
                period_unit
                display_order
                prescriptionPrescribed_medicineId
                createdAt
                updatedAt
                chartRecommended_medicinesId
              }
              nextToken
            }
            prescribed_time
            createdAt
            updatedAt
          }
          order_set_id
          orderSet {
            id
            kid
            status
            order_shipments {
              items {
                id
                kid
                status
                brand
                processor
                chart_id
                chart {
                  id
                  patient_name
                  user_id
                  yeyak_time
                  platform
                  kid
                  user_status
                  doctor_status
                  pharma_status
                  survey_answers
                  survey_type
                  user_msg
                  doctor_id
                  when
                  prescription_id
                  order_set_id
                  createdAt
                  updatedAt
                  userVenus_chartsId
                }
                processor_status
                updatedAt
                processor_id
                prescription_id
                prescription {
                  id
                  pharma_id
                  doctor_id
                  s3_key
                  s3_identity
                  status
                  prescribed_time
                  createdAt
                  updatedAt
                }
                total
                orders {
                  nextToken
                }
                road_address
                detail_address
                zip
                phone
                name
                delivery_company
                delivery_number
                createdAt
                orderSetOrder_shipmentsId
              }
              nextToken
            }
            chart_id
            total
            createdAt
            updatedAt
            userOrder_setsId
          }
          createdAt
          updatedAt
          userVenus_chartsId
        }
        nextToken
      }
      order_sets {
        items {
          id
          kid
          status
          order_shipments {
            items {
              id
              kid
              status
              brand
              processor
              chart_id
              chart {
                id
                patient_name
                user_id
                user_info {
                  id
                  name
                  age
                  phone
                  ssn
                  createdAt
                  updatedAt
                }
                yeyak_time
                platform
                kid
                user_status
                doctor_status
                pharma_status
                survey_answers
                survey_type
                user_msg
                doctor_id
                doctor {
                  id
                  doctor_name
                  office_name
                  office_address
                  office_phone
                  doctor_phone
                  createdAt
                  updatedAt
                }
                when
                recommended_medicines {
                  nextToken
                }
                prescription_id
                prescription {
                  id
                  pharma_id
                  doctor_id
                  s3_key
                  s3_identity
                  status
                  prescribed_time
                  createdAt
                  updatedAt
                }
                order_set_id
                orderSet {
                  id
                  kid
                  status
                  chart_id
                  total
                  createdAt
                  updatedAt
                  userOrder_setsId
                }
                createdAt
                updatedAt
                userVenus_chartsId
              }
              processor_status
              updatedAt
              processor_id
              prescription_id
              prescription {
                id
                pharma_id
                doctor_id
                doctor {
                  id
                  doctor_name
                  office_name
                  office_address
                  office_phone
                  doctor_phone
                  createdAt
                  updatedAt
                }
                s3_key
                s3_identity
                status
                prescribed_medicine {
                  nextToken
                }
                prescribed_time
                createdAt
                updatedAt
              }
              total
              orders {
                items {
                  id
                  category
                  product_name
                  product_id
                  product_count
                  product_unit
                  product_base
                  product_quantity_str
                  product_price
                  order_total_price
                  createdAt
                  updatedAt
                  orderShipmentOrdersId
                }
                nextToken
              }
              road_address
              detail_address
              zip
              phone
              name
              delivery_company
              delivery_number
              createdAt
              orderSetOrder_shipmentsId
            }
            nextToken
          }
          chart_id
          total
          createdAt
          updatedAt
          userOrder_setsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      user_info {
        id
        name
        age
        phone
        ssn
        addrs {
          items {
            id
            nick_name
            road_address
            detail_address
            zip
            is_default
            createdAt
            updatedAt
            userInfoAddrsId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      cards {
        items {
          id
          card_name
          card_company
          card_number
          billing_key
          last_used_time
          createdAt
          updatedAt
          userCardsId
        }
        nextToken
      }
      venus_charts {
        items {
          id
          patient_name
          user_id
          user_info {
            id
            name
            age
            phone
            ssn
            addrs {
              items {
                id
                nick_name
                road_address
                detail_address
                zip
                is_default
                createdAt
                updatedAt
                userInfoAddrsId
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          yeyak_time
          platform
          kid
          user_status
          doctor_status
          pharma_status
          survey_answers
          survey_type
          user_msg
          doctor_id
          doctor {
            id
            doctor_name
            office_name
            office_address
            office_phone
            doctor_phone
            createdAt
            updatedAt
          }
          when
          recommended_medicines {
            items {
              id
              medicine_info_id
              medicine {
                id
                type
                ingredient
                company
                product_name
                nick_name
                retail_price
                description
                unit
                base
                multiplier
                price_multiplier
                info_url
                display_order
                createdAt
                updatedAt
              }
              dosage
              period
              period_unit
              display_order
              prescriptionPrescribed_medicineId
              createdAt
              updatedAt
              chartRecommended_medicinesId
            }
            nextToken
          }
          prescription_id
          prescription {
            id
            pharma_id
            doctor_id
            doctor {
              id
              doctor_name
              office_name
              office_address
              office_phone
              doctor_phone
              createdAt
              updatedAt
            }
            s3_key
            s3_identity
            status
            prescribed_medicine {
              items {
                id
                medicine_info_id
                medicine {
                  id
                  type
                  ingredient
                  company
                  product_name
                  nick_name
                  retail_price
                  description
                  unit
                  base
                  multiplier
                  price_multiplier
                  info_url
                  display_order
                  createdAt
                  updatedAt
                }
                dosage
                period
                period_unit
                display_order
                prescriptionPrescribed_medicineId
                createdAt
                updatedAt
                chartRecommended_medicinesId
              }
              nextToken
            }
            prescribed_time
            createdAt
            updatedAt
          }
          order_set_id
          orderSet {
            id
            kid
            status
            order_shipments {
              items {
                id
                kid
                status
                brand
                processor
                chart_id
                chart {
                  id
                  patient_name
                  user_id
                  yeyak_time
                  platform
                  kid
                  user_status
                  doctor_status
                  pharma_status
                  survey_answers
                  survey_type
                  user_msg
                  doctor_id
                  when
                  prescription_id
                  order_set_id
                  createdAt
                  updatedAt
                  userVenus_chartsId
                }
                processor_status
                updatedAt
                processor_id
                prescription_id
                prescription {
                  id
                  pharma_id
                  doctor_id
                  s3_key
                  s3_identity
                  status
                  prescribed_time
                  createdAt
                  updatedAt
                }
                total
                orders {
                  nextToken
                }
                road_address
                detail_address
                zip
                phone
                name
                delivery_company
                delivery_number
                createdAt
                orderSetOrder_shipmentsId
              }
              nextToken
            }
            chart_id
            total
            createdAt
            updatedAt
            userOrder_setsId
          }
          createdAt
          updatedAt
          userVenus_chartsId
        }
        nextToken
      }
      order_sets {
        items {
          id
          kid
          status
          order_shipments {
            items {
              id
              kid
              status
              brand
              processor
              chart_id
              chart {
                id
                patient_name
                user_id
                user_info {
                  id
                  name
                  age
                  phone
                  ssn
                  createdAt
                  updatedAt
                }
                yeyak_time
                platform
                kid
                user_status
                doctor_status
                pharma_status
                survey_answers
                survey_type
                user_msg
                doctor_id
                doctor {
                  id
                  doctor_name
                  office_name
                  office_address
                  office_phone
                  doctor_phone
                  createdAt
                  updatedAt
                }
                when
                recommended_medicines {
                  nextToken
                }
                prescription_id
                prescription {
                  id
                  pharma_id
                  doctor_id
                  s3_key
                  s3_identity
                  status
                  prescribed_time
                  createdAt
                  updatedAt
                }
                order_set_id
                orderSet {
                  id
                  kid
                  status
                  chart_id
                  total
                  createdAt
                  updatedAt
                  userOrder_setsId
                }
                createdAt
                updatedAt
                userVenus_chartsId
              }
              processor_status
              updatedAt
              processor_id
              prescription_id
              prescription {
                id
                pharma_id
                doctor_id
                doctor {
                  id
                  doctor_name
                  office_name
                  office_address
                  office_phone
                  doctor_phone
                  createdAt
                  updatedAt
                }
                s3_key
                s3_identity
                status
                prescribed_medicine {
                  nextToken
                }
                prescribed_time
                createdAt
                updatedAt
              }
              total
              orders {
                items {
                  id
                  category
                  product_name
                  product_id
                  product_count
                  product_unit
                  product_base
                  product_quantity_str
                  product_price
                  order_total_price
                  createdAt
                  updatedAt
                  orderShipmentOrdersId
                }
                nextToken
              }
              road_address
              detail_address
              zip
              phone
              name
              delivery_company
              delivery_number
              createdAt
              orderSetOrder_shipmentsId
            }
            nextToken
          }
          chart_id
          total
          createdAt
          updatedAt
          userOrder_setsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createOrderShipment = /* GraphQL */ `
  mutation CreateOrderShipment(
    $input: CreateOrderShipmentInput!
    $condition: ModelOrderShipmentConditionInput
  ) {
    createOrderShipment(input: $input, condition: $condition) {
      id
      kid
      status
      brand
      processor
      chart_id
      chart {
        id
        patient_name
        user_id
        user_info {
          id
          name
          age
          phone
          ssn
          addrs {
            items {
              id
              nick_name
              road_address
              detail_address
              zip
              is_default
              createdAt
              updatedAt
              userInfoAddrsId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        yeyak_time
        platform
        kid
        user_status
        doctor_status
        pharma_status
        survey_answers
        survey_type
        user_msg
        doctor_id
        doctor {
          id
          doctor_name
          office_name
          office_address
          office_phone
          doctor_phone
          createdAt
          updatedAt
        }
        when
        recommended_medicines {
          items {
            id
            medicine_info_id
            medicine {
              id
              type
              ingredient
              company
              product_name
              nick_name
              retail_price
              description
              unit
              base
              multiplier
              price_multiplier
              info_url
              display_order
              createdAt
              updatedAt
            }
            dosage
            period
            period_unit
            display_order
            prescriptionPrescribed_medicineId
            createdAt
            updatedAt
            chartRecommended_medicinesId
          }
          nextToken
        }
        prescription_id
        prescription {
          id
          pharma_id
          doctor_id
          doctor {
            id
            doctor_name
            office_name
            office_address
            office_phone
            doctor_phone
            createdAt
            updatedAt
          }
          s3_key
          s3_identity
          status
          prescribed_medicine {
            items {
              id
              medicine_info_id
              medicine {
                id
                type
                ingredient
                company
                product_name
                nick_name
                retail_price
                description
                unit
                base
                multiplier
                price_multiplier
                info_url
                display_order
                createdAt
                updatedAt
              }
              dosage
              period
              period_unit
              display_order
              prescriptionPrescribed_medicineId
              createdAt
              updatedAt
              chartRecommended_medicinesId
            }
            nextToken
          }
          prescribed_time
          createdAt
          updatedAt
        }
        order_set_id
        orderSet {
          id
          kid
          status
          order_shipments {
            items {
              id
              kid
              status
              brand
              processor
              chart_id
              chart {
                id
                patient_name
                user_id
                user_info {
                  id
                  name
                  age
                  phone
                  ssn
                  createdAt
                  updatedAt
                }
                yeyak_time
                platform
                kid
                user_status
                doctor_status
                pharma_status
                survey_answers
                survey_type
                user_msg
                doctor_id
                doctor {
                  id
                  doctor_name
                  office_name
                  office_address
                  office_phone
                  doctor_phone
                  createdAt
                  updatedAt
                }
                when
                recommended_medicines {
                  nextToken
                }
                prescription_id
                prescription {
                  id
                  pharma_id
                  doctor_id
                  s3_key
                  s3_identity
                  status
                  prescribed_time
                  createdAt
                  updatedAt
                }
                order_set_id
                orderSet {
                  id
                  kid
                  status
                  chart_id
                  total
                  createdAt
                  updatedAt
                  userOrder_setsId
                }
                createdAt
                updatedAt
                userVenus_chartsId
              }
              processor_status
              updatedAt
              processor_id
              prescription_id
              prescription {
                id
                pharma_id
                doctor_id
                doctor {
                  id
                  doctor_name
                  office_name
                  office_address
                  office_phone
                  doctor_phone
                  createdAt
                  updatedAt
                }
                s3_key
                s3_identity
                status
                prescribed_medicine {
                  nextToken
                }
                prescribed_time
                createdAt
                updatedAt
              }
              total
              orders {
                items {
                  id
                  category
                  product_name
                  product_id
                  product_count
                  product_unit
                  product_base
                  product_quantity_str
                  product_price
                  order_total_price
                  createdAt
                  updatedAt
                  orderShipmentOrdersId
                }
                nextToken
              }
              road_address
              detail_address
              zip
              phone
              name
              delivery_company
              delivery_number
              createdAt
              orderSetOrder_shipmentsId
            }
            nextToken
          }
          chart_id
          total
          createdAt
          updatedAt
          userOrder_setsId
        }
        createdAt
        updatedAt
        userVenus_chartsId
      }
      processor_status
      updatedAt
      processor_id
      prescription_id
      prescription {
        id
        pharma_id
        doctor_id
        doctor {
          id
          doctor_name
          office_name
          office_address
          office_phone
          doctor_phone
          createdAt
          updatedAt
        }
        s3_key
        s3_identity
        status
        prescribed_medicine {
          items {
            id
            medicine_info_id
            medicine {
              id
              type
              ingredient
              company
              product_name
              nick_name
              retail_price
              description
              unit
              base
              multiplier
              price_multiplier
              info_url
              display_order
              createdAt
              updatedAt
            }
            dosage
            period
            period_unit
            display_order
            prescriptionPrescribed_medicineId
            createdAt
            updatedAt
            chartRecommended_medicinesId
          }
          nextToken
        }
        prescribed_time
        createdAt
        updatedAt
      }
      total
      orders {
        items {
          id
          category
          product_name
          product_id
          product_count
          product_unit
          product_base
          product_quantity_str
          product_price
          order_total_price
          createdAt
          updatedAt
          orderShipmentOrdersId
        }
        nextToken
      }
      road_address
      detail_address
      zip
      phone
      name
      delivery_company
      delivery_number
      createdAt
      orderSetOrder_shipmentsId
    }
  }
`;
export const updateOrderShipment = /* GraphQL */ `
  mutation UpdateOrderShipment(
    $input: UpdateOrderShipmentInput!
    $condition: ModelOrderShipmentConditionInput
  ) {
    updateOrderShipment(input: $input, condition: $condition) {
      id
      kid
      status
      brand
      processor
      chart_id
      chart {
        id
        patient_name
        user_id
        user_info {
          id
          name
          age
          phone
          ssn
          addrs {
            items {
              id
              nick_name
              road_address
              detail_address
              zip
              is_default
              createdAt
              updatedAt
              userInfoAddrsId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        yeyak_time
        platform
        kid
        user_status
        doctor_status
        pharma_status
        survey_answers
        survey_type
        user_msg
        doctor_id
        doctor {
          id
          doctor_name
          office_name
          office_address
          office_phone
          doctor_phone
          createdAt
          updatedAt
        }
        when
        recommended_medicines {
          items {
            id
            medicine_info_id
            medicine {
              id
              type
              ingredient
              company
              product_name
              nick_name
              retail_price
              description
              unit
              base
              multiplier
              price_multiplier
              info_url
              display_order
              createdAt
              updatedAt
            }
            dosage
            period
            period_unit
            display_order
            prescriptionPrescribed_medicineId
            createdAt
            updatedAt
            chartRecommended_medicinesId
          }
          nextToken
        }
        prescription_id
        prescription {
          id
          pharma_id
          doctor_id
          doctor {
            id
            doctor_name
            office_name
            office_address
            office_phone
            doctor_phone
            createdAt
            updatedAt
          }
          s3_key
          s3_identity
          status
          prescribed_medicine {
            items {
              id
              medicine_info_id
              medicine {
                id
                type
                ingredient
                company
                product_name
                nick_name
                retail_price
                description
                unit
                base
                multiplier
                price_multiplier
                info_url
                display_order
                createdAt
                updatedAt
              }
              dosage
              period
              period_unit
              display_order
              prescriptionPrescribed_medicineId
              createdAt
              updatedAt
              chartRecommended_medicinesId
            }
            nextToken
          }
          prescribed_time
          createdAt
          updatedAt
        }
        order_set_id
        orderSet {
          id
          kid
          status
          order_shipments {
            items {
              id
              kid
              status
              brand
              processor
              chart_id
              chart {
                id
                patient_name
                user_id
                user_info {
                  id
                  name
                  age
                  phone
                  ssn
                  createdAt
                  updatedAt
                }
                yeyak_time
                platform
                kid
                user_status
                doctor_status
                pharma_status
                survey_answers
                survey_type
                user_msg
                doctor_id
                doctor {
                  id
                  doctor_name
                  office_name
                  office_address
                  office_phone
                  doctor_phone
                  createdAt
                  updatedAt
                }
                when
                recommended_medicines {
                  nextToken
                }
                prescription_id
                prescription {
                  id
                  pharma_id
                  doctor_id
                  s3_key
                  s3_identity
                  status
                  prescribed_time
                  createdAt
                  updatedAt
                }
                order_set_id
                orderSet {
                  id
                  kid
                  status
                  chart_id
                  total
                  createdAt
                  updatedAt
                  userOrder_setsId
                }
                createdAt
                updatedAt
                userVenus_chartsId
              }
              processor_status
              updatedAt
              processor_id
              prescription_id
              prescription {
                id
                pharma_id
                doctor_id
                doctor {
                  id
                  doctor_name
                  office_name
                  office_address
                  office_phone
                  doctor_phone
                  createdAt
                  updatedAt
                }
                s3_key
                s3_identity
                status
                prescribed_medicine {
                  nextToken
                }
                prescribed_time
                createdAt
                updatedAt
              }
              total
              orders {
                items {
                  id
                  category
                  product_name
                  product_id
                  product_count
                  product_unit
                  product_base
                  product_quantity_str
                  product_price
                  order_total_price
                  createdAt
                  updatedAt
                  orderShipmentOrdersId
                }
                nextToken
              }
              road_address
              detail_address
              zip
              phone
              name
              delivery_company
              delivery_number
              createdAt
              orderSetOrder_shipmentsId
            }
            nextToken
          }
          chart_id
          total
          createdAt
          updatedAt
          userOrder_setsId
        }
        createdAt
        updatedAt
        userVenus_chartsId
      }
      processor_status
      updatedAt
      processor_id
      prescription_id
      prescription {
        id
        pharma_id
        doctor_id
        doctor {
          id
          doctor_name
          office_name
          office_address
          office_phone
          doctor_phone
          createdAt
          updatedAt
        }
        s3_key
        s3_identity
        status
        prescribed_medicine {
          items {
            id
            medicine_info_id
            medicine {
              id
              type
              ingredient
              company
              product_name
              nick_name
              retail_price
              description
              unit
              base
              multiplier
              price_multiplier
              info_url
              display_order
              createdAt
              updatedAt
            }
            dosage
            period
            period_unit
            display_order
            prescriptionPrescribed_medicineId
            createdAt
            updatedAt
            chartRecommended_medicinesId
          }
          nextToken
        }
        prescribed_time
        createdAt
        updatedAt
      }
      total
      orders {
        items {
          id
          category
          product_name
          product_id
          product_count
          product_unit
          product_base
          product_quantity_str
          product_price
          order_total_price
          createdAt
          updatedAt
          orderShipmentOrdersId
        }
        nextToken
      }
      road_address
      detail_address
      zip
      phone
      name
      delivery_company
      delivery_number
      createdAt
      orderSetOrder_shipmentsId
    }
  }
`;
export const deleteOrderShipment = /* GraphQL */ `
  mutation DeleteOrderShipment(
    $input: DeleteOrderShipmentInput!
    $condition: ModelOrderShipmentConditionInput
  ) {
    deleteOrderShipment(input: $input, condition: $condition) {
      id
      kid
      status
      brand
      processor
      chart_id
      chart {
        id
        patient_name
        user_id
        user_info {
          id
          name
          age
          phone
          ssn
          addrs {
            items {
              id
              nick_name
              road_address
              detail_address
              zip
              is_default
              createdAt
              updatedAt
              userInfoAddrsId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        yeyak_time
        platform
        kid
        user_status
        doctor_status
        pharma_status
        survey_answers
        survey_type
        user_msg
        doctor_id
        doctor {
          id
          doctor_name
          office_name
          office_address
          office_phone
          doctor_phone
          createdAt
          updatedAt
        }
        when
        recommended_medicines {
          items {
            id
            medicine_info_id
            medicine {
              id
              type
              ingredient
              company
              product_name
              nick_name
              retail_price
              description
              unit
              base
              multiplier
              price_multiplier
              info_url
              display_order
              createdAt
              updatedAt
            }
            dosage
            period
            period_unit
            display_order
            prescriptionPrescribed_medicineId
            createdAt
            updatedAt
            chartRecommended_medicinesId
          }
          nextToken
        }
        prescription_id
        prescription {
          id
          pharma_id
          doctor_id
          doctor {
            id
            doctor_name
            office_name
            office_address
            office_phone
            doctor_phone
            createdAt
            updatedAt
          }
          s3_key
          s3_identity
          status
          prescribed_medicine {
            items {
              id
              medicine_info_id
              medicine {
                id
                type
                ingredient
                company
                product_name
                nick_name
                retail_price
                description
                unit
                base
                multiplier
                price_multiplier
                info_url
                display_order
                createdAt
                updatedAt
              }
              dosage
              period
              period_unit
              display_order
              prescriptionPrescribed_medicineId
              createdAt
              updatedAt
              chartRecommended_medicinesId
            }
            nextToken
          }
          prescribed_time
          createdAt
          updatedAt
        }
        order_set_id
        orderSet {
          id
          kid
          status
          order_shipments {
            items {
              id
              kid
              status
              brand
              processor
              chart_id
              chart {
                id
                patient_name
                user_id
                user_info {
                  id
                  name
                  age
                  phone
                  ssn
                  createdAt
                  updatedAt
                }
                yeyak_time
                platform
                kid
                user_status
                doctor_status
                pharma_status
                survey_answers
                survey_type
                user_msg
                doctor_id
                doctor {
                  id
                  doctor_name
                  office_name
                  office_address
                  office_phone
                  doctor_phone
                  createdAt
                  updatedAt
                }
                when
                recommended_medicines {
                  nextToken
                }
                prescription_id
                prescription {
                  id
                  pharma_id
                  doctor_id
                  s3_key
                  s3_identity
                  status
                  prescribed_time
                  createdAt
                  updatedAt
                }
                order_set_id
                orderSet {
                  id
                  kid
                  status
                  chart_id
                  total
                  createdAt
                  updatedAt
                  userOrder_setsId
                }
                createdAt
                updatedAt
                userVenus_chartsId
              }
              processor_status
              updatedAt
              processor_id
              prescription_id
              prescription {
                id
                pharma_id
                doctor_id
                doctor {
                  id
                  doctor_name
                  office_name
                  office_address
                  office_phone
                  doctor_phone
                  createdAt
                  updatedAt
                }
                s3_key
                s3_identity
                status
                prescribed_medicine {
                  nextToken
                }
                prescribed_time
                createdAt
                updatedAt
              }
              total
              orders {
                items {
                  id
                  category
                  product_name
                  product_id
                  product_count
                  product_unit
                  product_base
                  product_quantity_str
                  product_price
                  order_total_price
                  createdAt
                  updatedAt
                  orderShipmentOrdersId
                }
                nextToken
              }
              road_address
              detail_address
              zip
              phone
              name
              delivery_company
              delivery_number
              createdAt
              orderSetOrder_shipmentsId
            }
            nextToken
          }
          chart_id
          total
          createdAt
          updatedAt
          userOrder_setsId
        }
        createdAt
        updatedAt
        userVenus_chartsId
      }
      processor_status
      updatedAt
      processor_id
      prescription_id
      prescription {
        id
        pharma_id
        doctor_id
        doctor {
          id
          doctor_name
          office_name
          office_address
          office_phone
          doctor_phone
          createdAt
          updatedAt
        }
        s3_key
        s3_identity
        status
        prescribed_medicine {
          items {
            id
            medicine_info_id
            medicine {
              id
              type
              ingredient
              company
              product_name
              nick_name
              retail_price
              description
              unit
              base
              multiplier
              price_multiplier
              info_url
              display_order
              createdAt
              updatedAt
            }
            dosage
            period
            period_unit
            display_order
            prescriptionPrescribed_medicineId
            createdAt
            updatedAt
            chartRecommended_medicinesId
          }
          nextToken
        }
        prescribed_time
        createdAt
        updatedAt
      }
      total
      orders {
        items {
          id
          category
          product_name
          product_id
          product_count
          product_unit
          product_base
          product_quantity_str
          product_price
          order_total_price
          createdAt
          updatedAt
          orderShipmentOrdersId
        }
        nextToken
      }
      road_address
      detail_address
      zip
      phone
      name
      delivery_company
      delivery_number
      createdAt
      orderSetOrder_shipmentsId
    }
  }
`;
export const createOrderSet = /* GraphQL */ `
  mutation CreateOrderSet(
    $input: CreateOrderSetInput!
    $condition: ModelOrderSetConditionInput
  ) {
    createOrderSet(input: $input, condition: $condition) {
      id
      kid
      status
      order_shipments {
        items {
          id
          kid
          status
          brand
          processor
          chart_id
          chart {
            id
            patient_name
            user_id
            user_info {
              id
              name
              age
              phone
              ssn
              addrs {
                items {
                  id
                  nick_name
                  road_address
                  detail_address
                  zip
                  is_default
                  createdAt
                  updatedAt
                  userInfoAddrsId
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            yeyak_time
            platform
            kid
            user_status
            doctor_status
            pharma_status
            survey_answers
            survey_type
            user_msg
            doctor_id
            doctor {
              id
              doctor_name
              office_name
              office_address
              office_phone
              doctor_phone
              createdAt
              updatedAt
            }
            when
            recommended_medicines {
              items {
                id
                medicine_info_id
                medicine {
                  id
                  type
                  ingredient
                  company
                  product_name
                  nick_name
                  retail_price
                  description
                  unit
                  base
                  multiplier
                  price_multiplier
                  info_url
                  display_order
                  createdAt
                  updatedAt
                }
                dosage
                period
                period_unit
                display_order
                prescriptionPrescribed_medicineId
                createdAt
                updatedAt
                chartRecommended_medicinesId
              }
              nextToken
            }
            prescription_id
            prescription {
              id
              pharma_id
              doctor_id
              doctor {
                id
                doctor_name
                office_name
                office_address
                office_phone
                doctor_phone
                createdAt
                updatedAt
              }
              s3_key
              s3_identity
              status
              prescribed_medicine {
                items {
                  id
                  medicine_info_id
                  dosage
                  period
                  period_unit
                  display_order
                  prescriptionPrescribed_medicineId
                  createdAt
                  updatedAt
                  chartRecommended_medicinesId
                }
                nextToken
              }
              prescribed_time
              createdAt
              updatedAt
            }
            order_set_id
            orderSet {
              id
              kid
              status
              order_shipments {
                items {
                  id
                  kid
                  status
                  brand
                  processor
                  chart_id
                  processor_status
                  updatedAt
                  processor_id
                  prescription_id
                  total
                  road_address
                  detail_address
                  zip
                  phone
                  name
                  delivery_company
                  delivery_number
                  createdAt
                  orderSetOrder_shipmentsId
                }
                nextToken
              }
              chart_id
              total
              createdAt
              updatedAt
              userOrder_setsId
            }
            createdAt
            updatedAt
            userVenus_chartsId
          }
          processor_status
          updatedAt
          processor_id
          prescription_id
          prescription {
            id
            pharma_id
            doctor_id
            doctor {
              id
              doctor_name
              office_name
              office_address
              office_phone
              doctor_phone
              createdAt
              updatedAt
            }
            s3_key
            s3_identity
            status
            prescribed_medicine {
              items {
                id
                medicine_info_id
                medicine {
                  id
                  type
                  ingredient
                  company
                  product_name
                  nick_name
                  retail_price
                  description
                  unit
                  base
                  multiplier
                  price_multiplier
                  info_url
                  display_order
                  createdAt
                  updatedAt
                }
                dosage
                period
                period_unit
                display_order
                prescriptionPrescribed_medicineId
                createdAt
                updatedAt
                chartRecommended_medicinesId
              }
              nextToken
            }
            prescribed_time
            createdAt
            updatedAt
          }
          total
          orders {
            items {
              id
              category
              product_name
              product_id
              product_count
              product_unit
              product_base
              product_quantity_str
              product_price
              order_total_price
              createdAt
              updatedAt
              orderShipmentOrdersId
            }
            nextToken
          }
          road_address
          detail_address
          zip
          phone
          name
          delivery_company
          delivery_number
          createdAt
          orderSetOrder_shipmentsId
        }
        nextToken
      }
      chart_id
      total
      createdAt
      updatedAt
      userOrder_setsId
    }
  }
`;
export const updateOrderSet = /* GraphQL */ `
  mutation UpdateOrderSet(
    $input: UpdateOrderSetInput!
    $condition: ModelOrderSetConditionInput
  ) {
    updateOrderSet(input: $input, condition: $condition) {
      id
      kid
      status
      order_shipments {
        items {
          id
          kid
          status
          brand
          processor
          chart_id
          chart {
            id
            patient_name
            user_id
            user_info {
              id
              name
              age
              phone
              ssn
              addrs {
                items {
                  id
                  nick_name
                  road_address
                  detail_address
                  zip
                  is_default
                  createdAt
                  updatedAt
                  userInfoAddrsId
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            yeyak_time
            platform
            kid
            user_status
            doctor_status
            pharma_status
            survey_answers
            survey_type
            user_msg
            doctor_id
            doctor {
              id
              doctor_name
              office_name
              office_address
              office_phone
              doctor_phone
              createdAt
              updatedAt
            }
            when
            recommended_medicines {
              items {
                id
                medicine_info_id
                medicine {
                  id
                  type
                  ingredient
                  company
                  product_name
                  nick_name
                  retail_price
                  description
                  unit
                  base
                  multiplier
                  price_multiplier
                  info_url
                  display_order
                  createdAt
                  updatedAt
                }
                dosage
                period
                period_unit
                display_order
                prescriptionPrescribed_medicineId
                createdAt
                updatedAt
                chartRecommended_medicinesId
              }
              nextToken
            }
            prescription_id
            prescription {
              id
              pharma_id
              doctor_id
              doctor {
                id
                doctor_name
                office_name
                office_address
                office_phone
                doctor_phone
                createdAt
                updatedAt
              }
              s3_key
              s3_identity
              status
              prescribed_medicine {
                items {
                  id
                  medicine_info_id
                  dosage
                  period
                  period_unit
                  display_order
                  prescriptionPrescribed_medicineId
                  createdAt
                  updatedAt
                  chartRecommended_medicinesId
                }
                nextToken
              }
              prescribed_time
              createdAt
              updatedAt
            }
            order_set_id
            orderSet {
              id
              kid
              status
              order_shipments {
                items {
                  id
                  kid
                  status
                  brand
                  processor
                  chart_id
                  processor_status
                  updatedAt
                  processor_id
                  prescription_id
                  total
                  road_address
                  detail_address
                  zip
                  phone
                  name
                  delivery_company
                  delivery_number
                  createdAt
                  orderSetOrder_shipmentsId
                }
                nextToken
              }
              chart_id
              total
              createdAt
              updatedAt
              userOrder_setsId
            }
            createdAt
            updatedAt
            userVenus_chartsId
          }
          processor_status
          updatedAt
          processor_id
          prescription_id
          prescription {
            id
            pharma_id
            doctor_id
            doctor {
              id
              doctor_name
              office_name
              office_address
              office_phone
              doctor_phone
              createdAt
              updatedAt
            }
            s3_key
            s3_identity
            status
            prescribed_medicine {
              items {
                id
                medicine_info_id
                medicine {
                  id
                  type
                  ingredient
                  company
                  product_name
                  nick_name
                  retail_price
                  description
                  unit
                  base
                  multiplier
                  price_multiplier
                  info_url
                  display_order
                  createdAt
                  updatedAt
                }
                dosage
                period
                period_unit
                display_order
                prescriptionPrescribed_medicineId
                createdAt
                updatedAt
                chartRecommended_medicinesId
              }
              nextToken
            }
            prescribed_time
            createdAt
            updatedAt
          }
          total
          orders {
            items {
              id
              category
              product_name
              product_id
              product_count
              product_unit
              product_base
              product_quantity_str
              product_price
              order_total_price
              createdAt
              updatedAt
              orderShipmentOrdersId
            }
            nextToken
          }
          road_address
          detail_address
          zip
          phone
          name
          delivery_company
          delivery_number
          createdAt
          orderSetOrder_shipmentsId
        }
        nextToken
      }
      chart_id
      total
      createdAt
      updatedAt
      userOrder_setsId
    }
  }
`;
export const deleteOrderSet = /* GraphQL */ `
  mutation DeleteOrderSet(
    $input: DeleteOrderSetInput!
    $condition: ModelOrderSetConditionInput
  ) {
    deleteOrderSet(input: $input, condition: $condition) {
      id
      kid
      status
      order_shipments {
        items {
          id
          kid
          status
          brand
          processor
          chart_id
          chart {
            id
            patient_name
            user_id
            user_info {
              id
              name
              age
              phone
              ssn
              addrs {
                items {
                  id
                  nick_name
                  road_address
                  detail_address
                  zip
                  is_default
                  createdAt
                  updatedAt
                  userInfoAddrsId
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            yeyak_time
            platform
            kid
            user_status
            doctor_status
            pharma_status
            survey_answers
            survey_type
            user_msg
            doctor_id
            doctor {
              id
              doctor_name
              office_name
              office_address
              office_phone
              doctor_phone
              createdAt
              updatedAt
            }
            when
            recommended_medicines {
              items {
                id
                medicine_info_id
                medicine {
                  id
                  type
                  ingredient
                  company
                  product_name
                  nick_name
                  retail_price
                  description
                  unit
                  base
                  multiplier
                  price_multiplier
                  info_url
                  display_order
                  createdAt
                  updatedAt
                }
                dosage
                period
                period_unit
                display_order
                prescriptionPrescribed_medicineId
                createdAt
                updatedAt
                chartRecommended_medicinesId
              }
              nextToken
            }
            prescription_id
            prescription {
              id
              pharma_id
              doctor_id
              doctor {
                id
                doctor_name
                office_name
                office_address
                office_phone
                doctor_phone
                createdAt
                updatedAt
              }
              s3_key
              s3_identity
              status
              prescribed_medicine {
                items {
                  id
                  medicine_info_id
                  dosage
                  period
                  period_unit
                  display_order
                  prescriptionPrescribed_medicineId
                  createdAt
                  updatedAt
                  chartRecommended_medicinesId
                }
                nextToken
              }
              prescribed_time
              createdAt
              updatedAt
            }
            order_set_id
            orderSet {
              id
              kid
              status
              order_shipments {
                items {
                  id
                  kid
                  status
                  brand
                  processor
                  chart_id
                  processor_status
                  updatedAt
                  processor_id
                  prescription_id
                  total
                  road_address
                  detail_address
                  zip
                  phone
                  name
                  delivery_company
                  delivery_number
                  createdAt
                  orderSetOrder_shipmentsId
                }
                nextToken
              }
              chart_id
              total
              createdAt
              updatedAt
              userOrder_setsId
            }
            createdAt
            updatedAt
            userVenus_chartsId
          }
          processor_status
          updatedAt
          processor_id
          prescription_id
          prescription {
            id
            pharma_id
            doctor_id
            doctor {
              id
              doctor_name
              office_name
              office_address
              office_phone
              doctor_phone
              createdAt
              updatedAt
            }
            s3_key
            s3_identity
            status
            prescribed_medicine {
              items {
                id
                medicine_info_id
                medicine {
                  id
                  type
                  ingredient
                  company
                  product_name
                  nick_name
                  retail_price
                  description
                  unit
                  base
                  multiplier
                  price_multiplier
                  info_url
                  display_order
                  createdAt
                  updatedAt
                }
                dosage
                period
                period_unit
                display_order
                prescriptionPrescribed_medicineId
                createdAt
                updatedAt
                chartRecommended_medicinesId
              }
              nextToken
            }
            prescribed_time
            createdAt
            updatedAt
          }
          total
          orders {
            items {
              id
              category
              product_name
              product_id
              product_count
              product_unit
              product_base
              product_quantity_str
              product_price
              order_total_price
              createdAt
              updatedAt
              orderShipmentOrdersId
            }
            nextToken
          }
          road_address
          detail_address
          zip
          phone
          name
          delivery_company
          delivery_number
          createdAt
          orderSetOrder_shipmentsId
        }
        nextToken
      }
      chart_id
      total
      createdAt
      updatedAt
      userOrder_setsId
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      category
      product_name
      product_id
      product_count
      product_unit
      product_base
      product_quantity_str
      product_price
      order_total_price
      createdAt
      updatedAt
      orderShipmentOrdersId
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      category
      product_name
      product_id
      product_count
      product_unit
      product_base
      product_quantity_str
      product_price
      order_total_price
      createdAt
      updatedAt
      orderShipmentOrdersId
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      category
      product_name
      product_id
      product_count
      product_unit
      product_base
      product_quantity_str
      product_price
      order_total_price
      createdAt
      updatedAt
      orderShipmentOrdersId
    }
  }
`;
export const createUserInfo = /* GraphQL */ `
  mutation CreateUserInfo(
    $input: CreateUserInfoInput!
    $condition: ModelUserInfoConditionInput
  ) {
    createUserInfo(input: $input, condition: $condition) {
      id
      name
      age
      phone
      ssn
      addrs {
        items {
          id
          nick_name
          road_address
          detail_address
          zip
          is_default
          createdAt
          updatedAt
          userInfoAddrsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUserInfo = /* GraphQL */ `
  mutation UpdateUserInfo(
    $input: UpdateUserInfoInput!
    $condition: ModelUserInfoConditionInput
  ) {
    updateUserInfo(input: $input, condition: $condition) {
      id
      name
      age
      phone
      ssn
      addrs {
        items {
          id
          nick_name
          road_address
          detail_address
          zip
          is_default
          createdAt
          updatedAt
          userInfoAddrsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserInfo = /* GraphQL */ `
  mutation DeleteUserInfo(
    $input: DeleteUserInfoInput!
    $condition: ModelUserInfoConditionInput
  ) {
    deleteUserInfo(input: $input, condition: $condition) {
      id
      name
      age
      phone
      ssn
      addrs {
        items {
          id
          nick_name
          road_address
          detail_address
          zip
          is_default
          createdAt
          updatedAt
          userInfoAddrsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createAddress = /* GraphQL */ `
  mutation CreateAddress(
    $input: CreateAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    createAddress(input: $input, condition: $condition) {
      id
      nick_name
      road_address
      detail_address
      zip
      is_default
      createdAt
      updatedAt
      userInfoAddrsId
    }
  }
`;
export const updateAddress = /* GraphQL */ `
  mutation UpdateAddress(
    $input: UpdateAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    updateAddress(input: $input, condition: $condition) {
      id
      nick_name
      road_address
      detail_address
      zip
      is_default
      createdAt
      updatedAt
      userInfoAddrsId
    }
  }
`;
export const deleteAddress = /* GraphQL */ `
  mutation DeleteAddress(
    $input: DeleteAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    deleteAddress(input: $input, condition: $condition) {
      id
      nick_name
      road_address
      detail_address
      zip
      is_default
      createdAt
      updatedAt
      userInfoAddrsId
    }
  }
`;
export const createCard = /* GraphQL */ `
  mutation CreateCard(
    $input: CreateCardInput!
    $condition: ModelCardConditionInput
  ) {
    createCard(input: $input, condition: $condition) {
      id
      card_name
      card_company
      card_number
      billing_key
      last_used_time
      createdAt
      updatedAt
      userCardsId
    }
  }
`;
export const updateCard = /* GraphQL */ `
  mutation UpdateCard(
    $input: UpdateCardInput!
    $condition: ModelCardConditionInput
  ) {
    updateCard(input: $input, condition: $condition) {
      id
      card_name
      card_company
      card_number
      billing_key
      last_used_time
      createdAt
      updatedAt
      userCardsId
    }
  }
`;
export const deleteCard = /* GraphQL */ `
  mutation DeleteCard(
    $input: DeleteCardInput!
    $condition: ModelCardConditionInput
  ) {
    deleteCard(input: $input, condition: $condition) {
      id
      card_name
      card_company
      card_number
      billing_key
      last_used_time
      createdAt
      updatedAt
      userCardsId
    }
  }
`;
export const createDoctor = /* GraphQL */ `
  mutation CreateDoctor(
    $input: CreateDoctorInput!
    $condition: ModelDoctorConditionInput
  ) {
    createDoctor(input: $input, condition: $condition) {
      id
      doctor_name
      office_name
      office_address
      office_phone
      doctor_phone
      createdAt
      updatedAt
    }
  }
`;
export const updateDoctor = /* GraphQL */ `
  mutation UpdateDoctor(
    $input: UpdateDoctorInput!
    $condition: ModelDoctorConditionInput
  ) {
    updateDoctor(input: $input, condition: $condition) {
      id
      doctor_name
      office_name
      office_address
      office_phone
      doctor_phone
      createdAt
      updatedAt
    }
  }
`;
export const deleteDoctor = /* GraphQL */ `
  mutation DeleteDoctor(
    $input: DeleteDoctorInput!
    $condition: ModelDoctorConditionInput
  ) {
    deleteDoctor(input: $input, condition: $condition) {
      id
      doctor_name
      office_name
      office_address
      office_phone
      doctor_phone
      createdAt
      updatedAt
    }
  }
`;
export const createPharma = /* GraphQL */ `
  mutation CreatePharma(
    $input: CreatePharmaInput!
    $condition: ModelPharmaConditionInput
  ) {
    createPharma(input: $input, condition: $condition) {
      id
      pharma_name
      office_name
      office_address
      office_phone
      pharma_phone
      createdAt
      updatedAt
    }
  }
`;
export const updatePharma = /* GraphQL */ `
  mutation UpdatePharma(
    $input: UpdatePharmaInput!
    $condition: ModelPharmaConditionInput
  ) {
    updatePharma(input: $input, condition: $condition) {
      id
      pharma_name
      office_name
      office_address
      office_phone
      pharma_phone
      createdAt
      updatedAt
    }
  }
`;
export const deletePharma = /* GraphQL */ `
  mutation DeletePharma(
    $input: DeletePharmaInput!
    $condition: ModelPharmaConditionInput
  ) {
    deletePharma(input: $input, condition: $condition) {
      id
      pharma_name
      office_name
      office_address
      office_phone
      pharma_phone
      createdAt
      updatedAt
    }
  }
`;
export const createPrescription = /* GraphQL */ `
  mutation CreatePrescription(
    $input: CreatePrescriptionInput!
    $condition: ModelPrescriptionConditionInput
  ) {
    createPrescription(input: $input, condition: $condition) {
      id
      pharma_id
      doctor_id
      doctor {
        id
        doctor_name
        office_name
        office_address
        office_phone
        doctor_phone
        createdAt
        updatedAt
      }
      s3_key
      s3_identity
      status
      prescribed_medicine {
        items {
          id
          medicine_info_id
          medicine {
            id
            type
            ingredient
            company
            product_name
            nick_name
            retail_price
            description
            unit
            base
            multiplier
            price_multiplier
            info_url
            display_order
            createdAt
            updatedAt
          }
          dosage
          period
          period_unit
          display_order
          prescriptionPrescribed_medicineId
          createdAt
          updatedAt
          chartRecommended_medicinesId
        }
        nextToken
      }
      prescribed_time
      createdAt
      updatedAt
    }
  }
`;
export const updatePrescription = /* GraphQL */ `
  mutation UpdatePrescription(
    $input: UpdatePrescriptionInput!
    $condition: ModelPrescriptionConditionInput
  ) {
    updatePrescription(input: $input, condition: $condition) {
      id
      pharma_id
      doctor_id
      doctor {
        id
        doctor_name
        office_name
        office_address
        office_phone
        doctor_phone
        createdAt
        updatedAt
      }
      s3_key
      s3_identity
      status
      prescribed_medicine {
        items {
          id
          medicine_info_id
          medicine {
            id
            type
            ingredient
            company
            product_name
            nick_name
            retail_price
            description
            unit
            base
            multiplier
            price_multiplier
            info_url
            display_order
            createdAt
            updatedAt
          }
          dosage
          period
          period_unit
          display_order
          prescriptionPrescribed_medicineId
          createdAt
          updatedAt
          chartRecommended_medicinesId
        }
        nextToken
      }
      prescribed_time
      createdAt
      updatedAt
    }
  }
`;
export const deletePrescription = /* GraphQL */ `
  mutation DeletePrescription(
    $input: DeletePrescriptionInput!
    $condition: ModelPrescriptionConditionInput
  ) {
    deletePrescription(input: $input, condition: $condition) {
      id
      pharma_id
      doctor_id
      doctor {
        id
        doctor_name
        office_name
        office_address
        office_phone
        doctor_phone
        createdAt
        updatedAt
      }
      s3_key
      s3_identity
      status
      prescribed_medicine {
        items {
          id
          medicine_info_id
          medicine {
            id
            type
            ingredient
            company
            product_name
            nick_name
            retail_price
            description
            unit
            base
            multiplier
            price_multiplier
            info_url
            display_order
            createdAt
            updatedAt
          }
          dosage
          period
          period_unit
          display_order
          prescriptionPrescribed_medicineId
          createdAt
          updatedAt
          chartRecommended_medicinesId
        }
        nextToken
      }
      prescribed_time
      createdAt
      updatedAt
    }
  }
`;
export const createMedicine = /* GraphQL */ `
  mutation CreateMedicine(
    $input: CreateMedicineInput!
    $condition: ModelMedicineConditionInput
  ) {
    createMedicine(input: $input, condition: $condition) {
      id
      medicine_info_id
      medicine {
        id
        type
        ingredient
        company
        product_name
        nick_name
        retail_price
        description
        unit
        base
        multiplier
        price_multiplier
        info_url
        display_order
        createdAt
        updatedAt
      }
      dosage
      period
      period_unit
      display_order
      prescriptionPrescribed_medicineId
      createdAt
      updatedAt
      chartRecommended_medicinesId
    }
  }
`;
export const updateMedicine = /* GraphQL */ `
  mutation UpdateMedicine(
    $input: UpdateMedicineInput!
    $condition: ModelMedicineConditionInput
  ) {
    updateMedicine(input: $input, condition: $condition) {
      id
      medicine_info_id
      medicine {
        id
        type
        ingredient
        company
        product_name
        nick_name
        retail_price
        description
        unit
        base
        multiplier
        price_multiplier
        info_url
        display_order
        createdAt
        updatedAt
      }
      dosage
      period
      period_unit
      display_order
      prescriptionPrescribed_medicineId
      createdAt
      updatedAt
      chartRecommended_medicinesId
    }
  }
`;
export const deleteMedicine = /* GraphQL */ `
  mutation DeleteMedicine(
    $input: DeleteMedicineInput!
    $condition: ModelMedicineConditionInput
  ) {
    deleteMedicine(input: $input, condition: $condition) {
      id
      medicine_info_id
      medicine {
        id
        type
        ingredient
        company
        product_name
        nick_name
        retail_price
        description
        unit
        base
        multiplier
        price_multiplier
        info_url
        display_order
        createdAt
        updatedAt
      }
      dosage
      period
      period_unit
      display_order
      prescriptionPrescribed_medicineId
      createdAt
      updatedAt
      chartRecommended_medicinesId
    }
  }
`;
export const createMedicineInfo = /* GraphQL */ `
  mutation CreateMedicineInfo(
    $input: CreateMedicineInfoInput!
    $condition: ModelMedicineInfoConditionInput
  ) {
    createMedicineInfo(input: $input, condition: $condition) {
      id
      type
      ingredient
      company
      product_name
      nick_name
      retail_price
      description
      unit
      base
      multiplier
      price_multiplier
      info_url
      display_order
      createdAt
      updatedAt
    }
  }
`;
export const updateMedicineInfo = /* GraphQL */ `
  mutation UpdateMedicineInfo(
    $input: UpdateMedicineInfoInput!
    $condition: ModelMedicineInfoConditionInput
  ) {
    updateMedicineInfo(input: $input, condition: $condition) {
      id
      type
      ingredient
      company
      product_name
      nick_name
      retail_price
      description
      unit
      base
      multiplier
      price_multiplier
      info_url
      display_order
      createdAt
      updatedAt
    }
  }
`;
export const deleteMedicineInfo = /* GraphQL */ `
  mutation DeleteMedicineInfo(
    $input: DeleteMedicineInfoInput!
    $condition: ModelMedicineInfoConditionInput
  ) {
    deleteMedicineInfo(input: $input, condition: $condition) {
      id
      type
      ingredient
      company
      product_name
      nick_name
      retail_price
      description
      unit
      base
      multiplier
      price_multiplier
      info_url
      display_order
      createdAt
      updatedAt
    }
  }
`;
export const createChart = /* GraphQL */ `
  mutation CreateChart(
    $input: CreateChartInput!
    $condition: ModelChartConditionInput
  ) {
    createChart(input: $input, condition: $condition) {
      id
      patient_name
      user_id
      user_info {
        id
        name
        age
        phone
        ssn
        addrs {
          items {
            id
            nick_name
            road_address
            detail_address
            zip
            is_default
            createdAt
            updatedAt
            userInfoAddrsId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      yeyak_time
      platform
      kid
      user_status
      doctor_status
      pharma_status
      survey_answers
      survey_type
      user_msg
      doctor_id
      doctor {
        id
        doctor_name
        office_name
        office_address
        office_phone
        doctor_phone
        createdAt
        updatedAt
      }
      when
      recommended_medicines {
        items {
          id
          medicine_info_id
          medicine {
            id
            type
            ingredient
            company
            product_name
            nick_name
            retail_price
            description
            unit
            base
            multiplier
            price_multiplier
            info_url
            display_order
            createdAt
            updatedAt
          }
          dosage
          period
          period_unit
          display_order
          prescriptionPrescribed_medicineId
          createdAt
          updatedAt
          chartRecommended_medicinesId
        }
        nextToken
      }
      prescription_id
      prescription {
        id
        pharma_id
        doctor_id
        doctor {
          id
          doctor_name
          office_name
          office_address
          office_phone
          doctor_phone
          createdAt
          updatedAt
        }
        s3_key
        s3_identity
        status
        prescribed_medicine {
          items {
            id
            medicine_info_id
            medicine {
              id
              type
              ingredient
              company
              product_name
              nick_name
              retail_price
              description
              unit
              base
              multiplier
              price_multiplier
              info_url
              display_order
              createdAt
              updatedAt
            }
            dosage
            period
            period_unit
            display_order
            prescriptionPrescribed_medicineId
            createdAt
            updatedAt
            chartRecommended_medicinesId
          }
          nextToken
        }
        prescribed_time
        createdAt
        updatedAt
      }
      order_set_id
      orderSet {
        id
        kid
        status
        order_shipments {
          items {
            id
            kid
            status
            brand
            processor
            chart_id
            chart {
              id
              patient_name
              user_id
              user_info {
                id
                name
                age
                phone
                ssn
                addrs {
                  nextToken
                }
                createdAt
                updatedAt
              }
              yeyak_time
              platform
              kid
              user_status
              doctor_status
              pharma_status
              survey_answers
              survey_type
              user_msg
              doctor_id
              doctor {
                id
                doctor_name
                office_name
                office_address
                office_phone
                doctor_phone
                createdAt
                updatedAt
              }
              when
              recommended_medicines {
                items {
                  id
                  medicine_info_id
                  dosage
                  period
                  period_unit
                  display_order
                  prescriptionPrescribed_medicineId
                  createdAt
                  updatedAt
                  chartRecommended_medicinesId
                }
                nextToken
              }
              prescription_id
              prescription {
                id
                pharma_id
                doctor_id
                doctor {
                  id
                  doctor_name
                  office_name
                  office_address
                  office_phone
                  doctor_phone
                  createdAt
                  updatedAt
                }
                s3_key
                s3_identity
                status
                prescribed_medicine {
                  nextToken
                }
                prescribed_time
                createdAt
                updatedAt
              }
              order_set_id
              orderSet {
                id
                kid
                status
                order_shipments {
                  nextToken
                }
                chart_id
                total
                createdAt
                updatedAt
                userOrder_setsId
              }
              createdAt
              updatedAt
              userVenus_chartsId
            }
            processor_status
            updatedAt
            processor_id
            prescription_id
            prescription {
              id
              pharma_id
              doctor_id
              doctor {
                id
                doctor_name
                office_name
                office_address
                office_phone
                doctor_phone
                createdAt
                updatedAt
              }
              s3_key
              s3_identity
              status
              prescribed_medicine {
                items {
                  id
                  medicine_info_id
                  dosage
                  period
                  period_unit
                  display_order
                  prescriptionPrescribed_medicineId
                  createdAt
                  updatedAt
                  chartRecommended_medicinesId
                }
                nextToken
              }
              prescribed_time
              createdAt
              updatedAt
            }
            total
            orders {
              items {
                id
                category
                product_name
                product_id
                product_count
                product_unit
                product_base
                product_quantity_str
                product_price
                order_total_price
                createdAt
                updatedAt
                orderShipmentOrdersId
              }
              nextToken
            }
            road_address
            detail_address
            zip
            phone
            name
            delivery_company
            delivery_number
            createdAt
            orderSetOrder_shipmentsId
          }
          nextToken
        }
        chart_id
        total
        createdAt
        updatedAt
        userOrder_setsId
      }
      createdAt
      updatedAt
      userVenus_chartsId
    }
  }
`;
export const updateChart = /* GraphQL */ `
  mutation UpdateChart(
    $input: UpdateChartInput!
    $condition: ModelChartConditionInput
  ) {
    updateChart(input: $input, condition: $condition) {
      id
      patient_name
      user_id
      user_info {
        id
        name
        age
        phone
        ssn
        addrs {
          items {
            id
            nick_name
            road_address
            detail_address
            zip
            is_default
            createdAt
            updatedAt
            userInfoAddrsId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      yeyak_time
      platform
      kid
      user_status
      doctor_status
      pharma_status
      survey_answers
      survey_type
      user_msg
      doctor_id
      doctor {
        id
        doctor_name
        office_name
        office_address
        office_phone
        doctor_phone
        createdAt
        updatedAt
      }
      when
      recommended_medicines {
        items {
          id
          medicine_info_id
          medicine {
            id
            type
            ingredient
            company
            product_name
            nick_name
            retail_price
            description
            unit
            base
            multiplier
            price_multiplier
            info_url
            display_order
            createdAt
            updatedAt
          }
          dosage
          period
          period_unit
          display_order
          prescriptionPrescribed_medicineId
          createdAt
          updatedAt
          chartRecommended_medicinesId
        }
        nextToken
      }
      prescription_id
      prescription {
        id
        pharma_id
        doctor_id
        doctor {
          id
          doctor_name
          office_name
          office_address
          office_phone
          doctor_phone
          createdAt
          updatedAt
        }
        s3_key
        s3_identity
        status
        prescribed_medicine {
          items {
            id
            medicine_info_id
            medicine {
              id
              type
              ingredient
              company
              product_name
              nick_name
              retail_price
              description
              unit
              base
              multiplier
              price_multiplier
              info_url
              display_order
              createdAt
              updatedAt
            }
            dosage
            period
            period_unit
            display_order
            prescriptionPrescribed_medicineId
            createdAt
            updatedAt
            chartRecommended_medicinesId
          }
          nextToken
        }
        prescribed_time
        createdAt
        updatedAt
      }
      order_set_id
      orderSet {
        id
        kid
        status
        order_shipments {
          items {
            id
            kid
            status
            brand
            processor
            chart_id
            chart {
              id
              patient_name
              user_id
              user_info {
                id
                name
                age
                phone
                ssn
                addrs {
                  nextToken
                }
                createdAt
                updatedAt
              }
              yeyak_time
              platform
              kid
              user_status
              doctor_status
              pharma_status
              survey_answers
              survey_type
              user_msg
              doctor_id
              doctor {
                id
                doctor_name
                office_name
                office_address
                office_phone
                doctor_phone
                createdAt
                updatedAt
              }
              when
              recommended_medicines {
                items {
                  id
                  medicine_info_id
                  dosage
                  period
                  period_unit
                  display_order
                  prescriptionPrescribed_medicineId
                  createdAt
                  updatedAt
                  chartRecommended_medicinesId
                }
                nextToken
              }
              prescription_id
              prescription {
                id
                pharma_id
                doctor_id
                doctor {
                  id
                  doctor_name
                  office_name
                  office_address
                  office_phone
                  doctor_phone
                  createdAt
                  updatedAt
                }
                s3_key
                s3_identity
                status
                prescribed_medicine {
                  nextToken
                }
                prescribed_time
                createdAt
                updatedAt
              }
              order_set_id
              orderSet {
                id
                kid
                status
                order_shipments {
                  nextToken
                }
                chart_id
                total
                createdAt
                updatedAt
                userOrder_setsId
              }
              createdAt
              updatedAt
              userVenus_chartsId
            }
            processor_status
            updatedAt
            processor_id
            prescription_id
            prescription {
              id
              pharma_id
              doctor_id
              doctor {
                id
                doctor_name
                office_name
                office_address
                office_phone
                doctor_phone
                createdAt
                updatedAt
              }
              s3_key
              s3_identity
              status
              prescribed_medicine {
                items {
                  id
                  medicine_info_id
                  dosage
                  period
                  period_unit
                  display_order
                  prescriptionPrescribed_medicineId
                  createdAt
                  updatedAt
                  chartRecommended_medicinesId
                }
                nextToken
              }
              prescribed_time
              createdAt
              updatedAt
            }
            total
            orders {
              items {
                id
                category
                product_name
                product_id
                product_count
                product_unit
                product_base
                product_quantity_str
                product_price
                order_total_price
                createdAt
                updatedAt
                orderShipmentOrdersId
              }
              nextToken
            }
            road_address
            detail_address
            zip
            phone
            name
            delivery_company
            delivery_number
            createdAt
            orderSetOrder_shipmentsId
          }
          nextToken
        }
        chart_id
        total
        createdAt
        updatedAt
        userOrder_setsId
      }
      createdAt
      updatedAt
      userVenus_chartsId
    }
  }
`;
export const deleteChart = /* GraphQL */ `
  mutation DeleteChart(
    $input: DeleteChartInput!
    $condition: ModelChartConditionInput
  ) {
    deleteChart(input: $input, condition: $condition) {
      id
      patient_name
      user_id
      user_info {
        id
        name
        age
        phone
        ssn
        addrs {
          items {
            id
            nick_name
            road_address
            detail_address
            zip
            is_default
            createdAt
            updatedAt
            userInfoAddrsId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      yeyak_time
      platform
      kid
      user_status
      doctor_status
      pharma_status
      survey_answers
      survey_type
      user_msg
      doctor_id
      doctor {
        id
        doctor_name
        office_name
        office_address
        office_phone
        doctor_phone
        createdAt
        updatedAt
      }
      when
      recommended_medicines {
        items {
          id
          medicine_info_id
          medicine {
            id
            type
            ingredient
            company
            product_name
            nick_name
            retail_price
            description
            unit
            base
            multiplier
            price_multiplier
            info_url
            display_order
            createdAt
            updatedAt
          }
          dosage
          period
          period_unit
          display_order
          prescriptionPrescribed_medicineId
          createdAt
          updatedAt
          chartRecommended_medicinesId
        }
        nextToken
      }
      prescription_id
      prescription {
        id
        pharma_id
        doctor_id
        doctor {
          id
          doctor_name
          office_name
          office_address
          office_phone
          doctor_phone
          createdAt
          updatedAt
        }
        s3_key
        s3_identity
        status
        prescribed_medicine {
          items {
            id
            medicine_info_id
            medicine {
              id
              type
              ingredient
              company
              product_name
              nick_name
              retail_price
              description
              unit
              base
              multiplier
              price_multiplier
              info_url
              display_order
              createdAt
              updatedAt
            }
            dosage
            period
            period_unit
            display_order
            prescriptionPrescribed_medicineId
            createdAt
            updatedAt
            chartRecommended_medicinesId
          }
          nextToken
        }
        prescribed_time
        createdAt
        updatedAt
      }
      order_set_id
      orderSet {
        id
        kid
        status
        order_shipments {
          items {
            id
            kid
            status
            brand
            processor
            chart_id
            chart {
              id
              patient_name
              user_id
              user_info {
                id
                name
                age
                phone
                ssn
                addrs {
                  nextToken
                }
                createdAt
                updatedAt
              }
              yeyak_time
              platform
              kid
              user_status
              doctor_status
              pharma_status
              survey_answers
              survey_type
              user_msg
              doctor_id
              doctor {
                id
                doctor_name
                office_name
                office_address
                office_phone
                doctor_phone
                createdAt
                updatedAt
              }
              when
              recommended_medicines {
                items {
                  id
                  medicine_info_id
                  dosage
                  period
                  period_unit
                  display_order
                  prescriptionPrescribed_medicineId
                  createdAt
                  updatedAt
                  chartRecommended_medicinesId
                }
                nextToken
              }
              prescription_id
              prescription {
                id
                pharma_id
                doctor_id
                doctor {
                  id
                  doctor_name
                  office_name
                  office_address
                  office_phone
                  doctor_phone
                  createdAt
                  updatedAt
                }
                s3_key
                s3_identity
                status
                prescribed_medicine {
                  nextToken
                }
                prescribed_time
                createdAt
                updatedAt
              }
              order_set_id
              orderSet {
                id
                kid
                status
                order_shipments {
                  nextToken
                }
                chart_id
                total
                createdAt
                updatedAt
                userOrder_setsId
              }
              createdAt
              updatedAt
              userVenus_chartsId
            }
            processor_status
            updatedAt
            processor_id
            prescription_id
            prescription {
              id
              pharma_id
              doctor_id
              doctor {
                id
                doctor_name
                office_name
                office_address
                office_phone
                doctor_phone
                createdAt
                updatedAt
              }
              s3_key
              s3_identity
              status
              prescribed_medicine {
                items {
                  id
                  medicine_info_id
                  dosage
                  period
                  period_unit
                  display_order
                  prescriptionPrescribed_medicineId
                  createdAt
                  updatedAt
                  chartRecommended_medicinesId
                }
                nextToken
              }
              prescribed_time
              createdAt
              updatedAt
            }
            total
            orders {
              items {
                id
                category
                product_name
                product_id
                product_count
                product_unit
                product_base
                product_quantity_str
                product_price
                order_total_price
                createdAt
                updatedAt
                orderShipmentOrdersId
              }
              nextToken
            }
            road_address
            detail_address
            zip
            phone
            name
            delivery_company
            delivery_number
            createdAt
            orderSetOrder_shipmentsId
          }
          nextToken
        }
        chart_id
        total
        createdAt
        updatedAt
        userOrder_setsId
      }
      createdAt
      updatedAt
      userVenus_chartsId
    }
  }
`;
export const createProductInfo = /* GraphQL */ `
  mutation CreateProductInfo(
    $input: CreateProductInfoInput!
    $condition: ModelProductInfoConditionInput
  ) {
    createProductInfo(input: $input, condition: $condition) {
      id
      type
      category
      ingredient
      company
      product_name
      product_img_id
      product_img_url
      nick_name
      retail_price
      description
      unit
      base
      multiplier
      price_multiplier
      info_url
      display_order
      createdAt
      updatedAt
    }
  }
`;
export const updateProductInfo = /* GraphQL */ `
  mutation UpdateProductInfo(
    $input: UpdateProductInfoInput!
    $condition: ModelProductInfoConditionInput
  ) {
    updateProductInfo(input: $input, condition: $condition) {
      id
      type
      category
      ingredient
      company
      product_name
      product_img_id
      product_img_url
      nick_name
      retail_price
      description
      unit
      base
      multiplier
      price_multiplier
      info_url
      display_order
      createdAt
      updatedAt
    }
  }
`;
export const deleteProductInfo = /* GraphQL */ `
  mutation DeleteProductInfo(
    $input: DeleteProductInfoInput!
    $condition: ModelProductInfoConditionInput
  ) {
    deleteProductInfo(input: $input, condition: $condition) {
      id
      type
      category
      ingredient
      company
      product_name
      product_img_id
      product_img_url
      nick_name
      retail_price
      description
      unit
      base
      multiplier
      price_multiplier
      info_url
      display_order
      createdAt
      updatedAt
    }
  }
`;

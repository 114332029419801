import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import 'react-tabs/style/react-tabs.css';
import Survey from "./Survey";
import React, { useEffect } from "react";

function SurveyTabs(props) {
  useEffect(()=>{
    console.log("use effect from SurveyTabs");
  },[])
  return (
    <div>
      <Tabs forceRenderTabPanel={true}>
        <TabList>
          {props.surveys.map((survey) =>
            <Tab key={`tab_${survey.id}`}>{survey.name}</Tab>
          )}
          <Tab>+</Tab>
        </TabList>

        {props.surveys.map((survey) =>
          <TabPanel key={`tab_panel_${survey.id}`}>
            <Survey  survey={survey} />
          </TabPanel>
        )}


        <TabPanel>
          <div>문진 데이타 추가</div>
        </TabPanel>
      </Tabs>
    </div>
  )
}

export default React.memo(SurveyTabs);
import { withAuthenticator } from "@aws-amplify/ui-react";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import { Button, Col, Container, Input, Row } from "reactstrap";
import ProductCard from "./ProductCard";
import { createProductInfoDB, getAllProductInfoDB } from "../../data/data";

function ProductPage(props) {
 const [products, setProducts] = useState([]);
 const [productId, setProductId] = useState("prod_" + nanoid(6));

 useEffect(() => {
  async function wrapper() {
   let res = await getAllProductInfoDB();
   console.log(res);
   if (res) {
    setProducts(res);
   }
  }
  wrapper();
 }, [])
 async function addProduct() {
  let productInfo = {
   id: productId,
   type: "COMMERCE",
   company: "",
   product_name: "",
   nick_name: "",
   retail_price: 0,
   description: ""
  }
  let product = await createProductInfoDB(productInfo);
  console.log(product);
 }
 return (
  <>
   <Container style={{ paddingTop: "12px", marginBottom:"100px" }}>
    <Row>
     <Col>
      <Input type={"text"} placeholder="name" value={productId} onChange={(e) => { setProductId(e.target.value) }} />
     </Col>
     <Col>
      <Button onClick={addProduct} color="primary">상품 추가하기</Button>
     </Col>
    </Row>

    <Row>
     {products.map(product =>
      <Col md="6">
       <ProductCard product={product} />
      </Col>
     )}
     <Col>
     </Col>
    </Row>
    
   </Container>
  </>
 )
}

export default withAuthenticator(ProductPage);
import { Button, Col, Input, Row } from "reactstrap";
import QnaCard from "./QnaCard";
import React, { useEffect, useState } from "react";
import { nanoid } from "nanoid";
import { createSurveyQna } from "../../data/data";

function Survey(props) {
  // states
  //
  const [qnas, setQnas] = useState([]);
  const [qnaID, setQnaID] = useState(`question_${nanoid(5)}`);

  useEffect(() => {
    console.log("Survey useeffect called");
    // console.log("qnas:", qnas);
    console.log("props.survey:", props.survey.questions_answers.items)
    let qnas = props.survey.questions_answers.items;
    if (qnas.length > 0) {
      qnas.sort((a, b) => a.question_number - b.question_number);
      setQnas(qnas);
    }
  }, [])

  // methods
  //
  async function addQNA() {
    let newQNA = {
      id: "qna_" + nanoid(10),
      question_id: qnaID,
      question_number: qnas.length + 1,
      question_title: "질문을 입력해주세요",
      question_type: "SINGLE",
      surveyQuestions_answersId: props.survey.id
    }
    console.log("newQNAInfo: ", newQNA)

    const res = await createSurveyQna(newQNA);
    console.log(res);

    setQnas(current => [...current, newQNA]);

  }

  return (
    <>
      <Row>
        <Col md="8">
          <div>
            Survey_ID: {props.survey.id} Description: {props.survey.comment}
          </div>
          <div>
            Platform: {props.survey.survey_platform} Version: {props.survey.version}
          </div>
        </Col>
        <Col md="4">
          <Input value={qnaID} onChange={(e)=>{setQnaID(e.target.value)}} ></Input>
          <Button style={{ marginTop: "10px", width: "100%" }}
            onClick={addQNA} size="md" color="primary" >질문 추가하기</Button>
        </Col>
      </Row>

      <Row style={{ paddingTop: "20px", paddingBottom: "100px" }}>
        {/* displaying QNAS */}
        {qnas.map(qna =>
          <Col key={`qna_${qna.id}`} md="6">
            <QnaCard qna={qna} />
          </Col>
        )}
      </Row>
    </>
  )
}

export default React.memo(Survey);
import { useEffect, useState } from "react";
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Input, InputGroup, InputGroupText, Row } from "reactstrap";
import { updateMedicineInfo } from "../../data/data";

function MedicineCard(props) {
 const [med, setMed] = useState(props.med);
 const [productName, setProductName] = useState(props.med.product_name);
 const [medicineId, setMedicineId] = useState(props.med.id);
 const [type, setType] = useState(props.med.type);
 const [company, setCompany] = useState(props.med.company);
 const [nickName, setNickName] = useState(props.med.nick_name);
 const [ingredient, setIngredient] = useState(props.med.ingredient);
 const [retailPrice, setRetailPrice] = useState(props.med.retail_price);
 const [unit, setUnit] = useState(props.med.unit);
 const [base, setBase] = useState(props.med.base);
 const [displayOrder, setDisplayOrer] = useState(props.med.display_order);
 const [multiplier, setMultiplier] = useState(props.med.multiplier);
 const [priceMultiplier, setPriceMultiplier] = useState(props.med.price_multiplier);

 async function saveMedicine() {
  let medicineInfo = {
   id: med.id,
   product_name: productName,
   type: type,
   company: company,
   ingredient: ingredient,
   nick_name: nickName,
   retail_price: retailPrice,
   base: base,
   unit: unit,
   multiplier: multiplier,
   price_multiplier: priceMultiplier,
   display_order: displayOrder,
  }
  await updateMedicineInfo(medicineInfo);
 }

 return (
  <Card style={{margin:"10px"}}>
   <CardHeader>
    {med.product_name}
   </CardHeader>
   <CardBody>
    <InputGroup size="sm" style={{ marginTop: "4px" }}>
     <InputGroupText style={{ width: "30%" }}>
      ID
     </InputGroupText>
     <Input value={medicineId} type="text" onChange={(e) => {
      setMedicineId(e.target.value);
     }} />
    </InputGroup>
    <InputGroup size="sm" style={{ marginTop: "4px" }}>
     <InputGroupText style={{ width: "30%" }}>
      display_order
     </InputGroupText>
     <Input value={displayOrder} type="text" onChange={(e) => {
      setDisplayOrer(e.target.value);
     }} />
    </InputGroup>

    <InputGroup size="sm" style={{ marginTop: "4px" }}>
     <InputGroupText style={{ width: "30%" }}>
      제품명
     </InputGroupText>
     <Input value={productName} type="text" onChange={(e) => {
      setProductName(e.target.value);
     }} />
    </InputGroup>

    <InputGroup size="sm" style={{ marginTop: "4px" }}>
     <InputGroupText style={{ width: "30%" }}>
      닉네임
     </InputGroupText>
     <Input value={nickName} type="text" onChange={(e) => {
      setNickName(e.target.value);
     }} />
    </InputGroup>

    <InputGroup size="sm" style={{ marginTop: "4px" }}>
     <InputGroupText style={{ width: "30%" }}>
      제약회사
     </InputGroupText>
     <Input value={company} type="text" onChange={(e) => {
      setCompany(e.target.value);
     }} />
    </InputGroup>

    <InputGroup size="sm" style={{ marginTop: "4px" }}>
     <InputGroupText style={{ width: "30%" }}>
      성분명
     </InputGroupText>
     <Input value={ingredient} type="text" onChange={(e) => {
      setIngredient(e.target.value);
     }} />
    </InputGroup>

    <InputGroup size="sm" style={{ marginTop: "4px" }}>
     <InputGroupText style={{ width: "30%" }}>
      retail_price
     </InputGroupText>
     <Input value={retailPrice} type="text" onChange={(e) => {
      setRetailPrice(e.target.value);
     }} />
    </InputGroup>

    <InputGroup size="sm" style={{ marginTop: "4px" }}>
     <InputGroupText style={{ width: "30%" }}>
      base
     </InputGroupText>
     <Input value={base} type="text" onChange={(e) => {
      setBase(e.target.value);
     }} />
    </InputGroup>

    <InputGroup size="sm" style={{ marginTop: "4px" }}>
     <InputGroupText style={{ width: "30%" }}>
      unit
     </InputGroupText>
     <Input value={unit} type="text" onChange={(e) => {
      setUnit(e.target.value);
     }} />
    </InputGroup>

    <InputGroup size="sm" style={{ marginTop: "4px" }}>
     <InputGroupText style={{ width: "30%" }}>
      multiplier
     </InputGroupText>
     <Input value={multiplier} type="text" onChange={(e) => {
      setMultiplier(e.target.value);
     }} />
    </InputGroup>

    <InputGroup size="sm" style={{ marginTop: "4px" }}>
     <InputGroupText style={{ width: "30%" }}>
      price_multiplier
     </InputGroupText>
     <Input value={priceMultiplier} type="text" onChange={(e) => {
      setPriceMultiplier(e.target.value);
     }} />
    </InputGroup>


    <InputGroup size="sm" style={{ marginTop: "4px" }}>
     <InputGroupText style={{ width: "30%" }}>
      약타입
     </InputGroupText>
     <Input value={type} type="select" onChange={(e) => {
      setType(e.target.value);
     }}>
      <option>
       MED_PILL
      </option>
      <option>
       MED_APPLY
      </option>
      <option>
       OTC_PILL
      </option>
      <option>
       OTC_APPLY
      </option>
     </Input>
    </InputGroup>

   </CardBody>
   <CardFooter>
    <Row>
     <Col><Button color="primary" onClick={saveMedicine}>저장하기</Button></Col>
    </Row>
   </CardFooter>
  </Card>
 )
}

export default MedicineCard;
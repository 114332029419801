import { useEffect, useState } from "react";
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Input, InputGroup, InputGroupText, Row } from "reactstrap";
import { createSurveyAnswer, removeAnswerDB, removeQnaDB, updateSurveyQna } from "../../data/data";
import { nanoid } from "nanoid";
import Answer from "./Answer";

export default function QnaCard(props) {

  const [title, setTitle] = useState(props.qna.question_title);
  const [shortTitle, setShortTitle] = useState(props.qna.question_title_short);
  const [subTitle, setSubTitle] = useState(props.qna.question_subtitle);
  const [qna, setQna] = useState(props.qna);
  const [answers, setAnswers] = useState([])
  const [questionID, setQuestionID] = useState(props.qna.question_id);
  const [questionNumber, setQuestionNumber] = useState(props.qna.question_number);
  const [questionType, setQuestionType] = useState(props.qna.question_type);
  const [questionBtnType, setQuestionBtnType] = useState(props.qna.question_btn_type || "RECT");
  const [maxChoice, setMaxChoice] = useState(props.qna.max_choice || 1);

  useEffect(() => {
    if (props.qna.answers.items) {
      let ans = props.qna.answers.items;
      if (ans.length > 0) {
        ans.sort((a, b) => a.answer_order - b.answer_order);
        setAnswers(ans);
      }
    }
  }, [])

  async function addAnswer() {
    let newAnswer = {
      id: "ans_" + nanoid(6),
      answer_order: answers.length + 1,
      answer_button_type: "RECT",
      answer_title: "답변",
      answer_value: "",
      answer_img_id: "",
      answer_img_url: "",
      surveyQNAAnswersId: props.qna.id
    }
    const res = await createSurveyAnswer(newAnswer);
    console.log(res);

    setAnswers(current => [...current, newAnswer]);
  }
  async function saveQna() {
    if (!window.confirm("변경사항을 저장 하시겠습니까?")) {
      return;
    }
    // console.log("saving qna called");
    let qnaInfo = {
      id: qna.id,
      question_id: questionID,
      question_title: title,
      question_title_short: shortTitle,
      question_subtitle: subTitle,
      question_number: questionNumber,
      question_type: questionType,
      question_btn_type: questionBtnType,
      max_choice: maxChoice,
    }
    const res = await updateSurveyQna(qnaInfo);
    console.log("updated qna:", res);
  }

  async function deleteQna() {
    if (!window.confirm("정말로 해당 질문을 지우시겠습니까??")) {
      return;
    }
    console.log("qna:", qna)

    // remove answers from qna
    if (qna.answers && qna.answers.items.length > 0) {
      for (let answer of qna.answers.items) {
        // remove answers
        await removeAnswerDB(answer.id);
      }
    }

    // remove QNA
    await removeQnaDB(qna.id);
    

  }

  return (
    <>
      <Card style={{ minHeight: "300px", marginTop: "20px" }}>
        <CardHeader>
          {/* Question_ID: {qna.question_id} */}
          <InputGroup>
            <Input type="text" placeholder="question_ID" value={questionID} onChange={(e) => {
              setQuestionID(e.target.value);
            }} />
          </InputGroup>

          <InputGroup>
            <Input type="text" placeholder="질문을 입력해 주세요" value={title} onChange={(e) => {
              setTitle(e.target.value);
            }} />
          </InputGroup>

          <InputGroup style={{ marginTop: "4px" }}>
            <Input type="text" placeholder="subtitle..." value={subTitle} onChange={(e) => {
              setSubTitle(e.target.value);
            }} />
          </InputGroup>

          <InputGroup style={{ marginTop: "4px" }}>
            <Input type="text" placeholder="short version.." value={shortTitle} onChange={(e) => {
              setShortTitle(e.target.value);
            }} />
          </InputGroup>


        </CardHeader>
        <CardBody>
          <InputGroup size="sm">
            <InputGroupText style={{ width: "30%" }}>
              질문 번호
            </InputGroupText>
            <Input value={questionNumber} type="text" onChange={(e) => {
              setQuestionNumber(e.target.value);
            }} />
          </InputGroup>

          <InputGroup size="sm" style={{ marginTop: "4px" }}>
            <InputGroupText style={{ width: "30%" }}>
              답변 타입
            </InputGroupText>
            <Input value={questionType} type="select" onChange={(e) => {
              setQuestionType(e.target.value);
            }}>
              <option>
                SINGLE
              </option>
              <option>
                MULTI
              </option>
              <option>
                PHOTO
              </option>
            </Input>
          </InputGroup>
          <InputGroup size="sm">
            <InputGroupText style={{ width: "30%" }}>
              max_choice
            </InputGroupText>
            <Input value={maxChoice} type="text" onChange={(e) => {
              setMaxChoice(e.target.value);
            }} />
          </InputGroup>

          <InputGroup size="sm">
            <InputGroupText style={{ width: "30%" }}>
              btn_type
            </InputGroupText>
            <Input value={questionBtnType} type="select" onChange={(e) => {
              setQuestionBtnType(e.target.value);
            }}>
              <option>
                RECT
              </option>
              <option>
                SQUARE
              </option>
              <option>
                CIRCLE
              </option>
            </Input>
          </InputGroup>


          <br />
          <Row style={{ paddingTop: "10px" }}>
            <Col md="12">
              <Button onClick={addAnswer} outline style={{ width: "100%", height: "50px" }}>답변 추가하기</Button>
            </Col>

            {answers.map(answer =>
              <Col key={`ans_${answer.id}`} md="6">
                <Answer answer={answer} />
              </Col>
            )}

          </Row>
        </CardBody>
        <CardFooter>
          <Row>
            <Col md="6">
              <Button style={{ width: "100%" }} color="primary" onClick={saveQna}>저장하기</Button>
            </Col>
            <Col md="6">
              <Button style={{ width: "100%" }} outline onClick={deleteQna}>질문 삭제하기</Button>
            </Col>
          </Row>
        </CardFooter>
      </Card>
    </>
  )
}
import Picker from "rmc-picker";
import { formatDisplayDate, formatDisplayTime } from "../util/Utils";

export const getAvailTimes = (times) => {

  // first get to the current date position
  let today = new Date();
  let idx = 0;
  for (idx; idx < times.length; ++idx) {
    // if (times[idx].time.startsWith(today.toISOString().split("T")[0])) {
    if (today.getDate() === new Date(times[idx].time).getDate()) {
      // console.log(times[idx]);
      break;
    }
  }
  // console.log("idx: ", idx);

  times.splice(0, idx);

  let slots = [];
  let counter = 0;
  let index = 0;
  let firstTime;
  let secondTime;
  let thirdTime;

  // get first date
  for (let t of times) {
    if (t.isAvail) {
      firstTime = t.time;
      break;
    }
  }

  for (let t of times) {
    index++;
    if (!t.time.startsWith(firstTime.split('T')[0])) {
      if (t.isAvail) {
        secondTime = t.time;
        break;
      }
    }
  }

  for (let i = index; i < times.length; i++) {
    if (!times[i].time.startsWith(secondTime.split('T')[0])) {
      if (times[i].isAvail) {
        thirdTime = times[i].time;
        break;
      }
    }
  }

  // console.log("firstTime: ", firstTime);
  // console.log("secondTime: ", secondTime);
  // console.log("thirdTime: ", thirdTime);

  let isFirstInit = true;
  let isSecondInit = true;
  let isThirdInit = true;

  for (let t of times) {
    if (t.time.startsWith(firstTime.split('T')[0])) {
      if (t.isAvail) {
        if (isFirstInit) {
          slots.push(
            <Picker.Item value={firstTime.split('T')[0]} key={counter++}>
              {/* [ {firstTime.split('T')[0]} ] */}
              [ {formatDisplayDate(firstTime)} ]
            </Picker.Item>
          );
          isFirstInit = false;
        }

        // 날짜가 같은경우 현재 시간보다 뒤에 나오는 시간만 보여준다
        let curTime = new Date();
        let selTime = new Date(t.time);
        if (selTime.getDate() === curTime.getDate()) {
          if (selTime.getHours() >= curTime.getHours()) {
            // console.log((curTime.getTime() - selTime.getTime()) / (1000 * 60))
            // adding 30 min buffer for yeyakTime.
            if (((curTime.getTime() - selTime.getTime()) / (1000 * 60)) <= 30) {
              slots.push(
                <Picker.Item value={t.time} key={counter++}>
                  {formatDisplayTime(t.time)}
                </Picker.Item>
              );
            }
          }
        } else {
          slots.push(
            <Picker.Item value={t.time} key={counter++}>
              {formatDisplayTime(t.time)}
            </Picker.Item>
          );
        }

        // }
      }
    }

    if (t.time.startsWith(secondTime.split('T')[0])) {
      if (t.isAvail) {
        if (isSecondInit) {
          slots.push(
            <Picker.Item value={secondTime.split('T')[0]} key={counter++}>
              [ {formatDisplayDate(secondTime)} ]
            </Picker.Item>
          );
          isSecondInit = false;
        }
        slots.push(
          <Picker.Item value={t.time} key={counter++}>
            {formatDisplayTime(t.time)}
          </Picker.Item>
        );
      }
    }

    if (t.time.startsWith(thirdTime.split('T')[0])) {
      if (t.isAvail) {
        if (isThirdInit) {
          slots.push(
            <Picker.Item value={thirdTime.split('T')[0]} key={counter++}>
              [ {formatDisplayDate(thirdTime)} ]
            </Picker.Item>
          );
          isThirdInit = false;
        }

        slots.push(
          <Picker.Item value={t.time} key={counter++}>
            {formatDisplayTime(t.time)}
          </Picker.Item>
        );
      }
    }
  }

  // // check start date and 48th date
  // let startTime = times[0].time;
  // let midTime = "";
  // let endTimie = "";

  // for (let t of times) {
  //   index++;
  //   if (t.isAvail) {
  //     counter++;
  //     console.log("counter: ", counter)
  //   }
  //   if (counter === 20) {
  //     midTime = t.time;
  //     break;
  //   }
  // }

  // starting from index finish midtime



  // console.log("startTime: ", new Date(startTime), "midTime: ", new Date(midTime));

  // calculate end date


  // slots.push(
  //   <Picker.Item value={dates[0]} >
  //     { toDisplayDateString(dates[0]) }
  //   </Picker.Item>
  // )

  // let h = 9;
  // if (hour < 22) {
  //   slot.push(
  //     <Picker.Item value={today.getMonth() + 1 + "/" + today.getDate()} key={counter++}>
  //       [ {month + 1 + "월" + date + "일 " + yoil[day] + "요일"} ]
  //     </Picker.Item>
  //   );
  //   if (hour < 9) {
  //     hour = 9;
  //   }
  //   for (let t = hour; t < 18; t++) {
  //     slot.push(
  //       <Picker.Item value={today.getMonth() + 1 + "/" + today.getDate() + "/" + yoil[today.getDay()] + "/" + t} key={counter++}>
  //         {t}:00 - {t + 1}:00
  //       </Picker.Item>
  //     );
  //   }
  // }




  return slots;
};
export default getAvailTimes;
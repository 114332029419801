import { API } from "aws-amplify";
import { Button, Col, Container, Input, InputGroup, InputGroupText, Row } from "reactstrap";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import SurveyTabs from "./SurveyTabs";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { getAllSurveys, createSurvey } from "../../data/data";

function SurveyPage(props) {
 const [surveyId, setSurveyId] = useState("venus_" + nanoid(5));
 const [surveyName, setSurveyName] = useState("");
 const [surveyVersion, setSurveyVersion] = useState("v1.0");
 const [surveyPlatform, setSurveyPlatform] = useState("VENUS");
 const [surveyComment, setSurveyComment] = useState("");
 const [surveys, setSurveys] = useState([]);

 async function addSurvey() {
  let surveyInfo = {
   id: surveyId,
   name: surveyName,
   survey_platform: surveyPlatform,
   version: surveyVersion,
   comment: surveyComment
  }

  const newSurvey = await createSurvey(surveyInfo);
  console.log(newSurvey);
 }

 useEffect(() => {
  console.log("useeffect from admin Page!");
  async function asyncWrapper() {
   let res = await getAllSurveys();
   console.log("res:", res);
   setSurveys(res);
  };
  asyncWrapper();
 }, [])

 return (
  <Container>
   <br />
   {/* <Row>
    <Col md="6">
     <InputGroup size="sm">
      <InputGroupText style={{ width: "30%" }}>
       Survey ID
      </InputGroupText>
      <Input placeholder="Survey ID" value={surveyId} onChange={(e) => {
       setSurveyId(e.target.value);
      }} />
     </InputGroup>

     <InputGroup size="sm">
      <InputGroupText style={{ width: "30%" }}>
       Survey Name
      </InputGroupText>
      <Input invalid={surveyName.length === 0} placeholder="Survey Name" value={surveyName} onChange={(e) => {
       setSurveyName(e.target.value);
      }} />
     </InputGroup>

     <InputGroup size="sm">
      <InputGroupText style={{ width: "30%" }}>
       Survey Version
      </InputGroupText>
      <Input placeholder="Survey Version" value={surveyVersion} onChange={(e) => {
       setSurveyVersion(e.target.value);
      }} />
     </InputGroup>

     <InputGroup size="sm">
      <InputGroupText style={{ width: "30%" }}>
       Survey Comment
      </InputGroupText>
      <Input style={{ border: "1px solid #cecece", borderLeft: "0px" }} type="textarea" placeholder="Survey Comment" value={surveyComment} onChange={(e) => {
       setSurveyComment(e.target.value);
      }} />
     </InputGroup>

     <InputGroup size="sm">
      <InputGroupText style={{ width: "30%" }}>
       Survey Platform
      </InputGroupText>
      <Input type="select" value={surveyPlatform} onChange={(e) => {
       setSurveyPlatform(e.target.value);
      }}>
       <option>
        MARS
       </option>
       <option>
        VENUS
       </option>
       <option>
        PLUTO
       </option>
      </Input>
     </InputGroup>
     <Button color="primary" onClick={addSurvey}>Add survey</Button>
    </Col>
   </Row> */}
   <br />
   <Row>
    <Col>
     <SurveyTabs surveys={surveys} />
    </Col>

   </Row>

  </Container>
 )
}

export default SurveyPage;
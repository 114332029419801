import { useEffect, useState } from "react";
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Input, InputGroup, InputGroupText, Row } from "reactstrap";
import { updateMedicineInfo, updateProductInfoDB } from "../../data/data";

function ProductCard(props) {
 const [product, setMed] = useState(props.product);
 const [productName, setProductName] = useState(props.product.product_name);
 const [productId, setProductId] = useState(props.product.id);
 const [type, setType] = useState(props.product.type);
 const [company, setCompany] = useState(props.product.company);
 const [nickName, setNickName] = useState(props.product.nick_name);
 const [ingredient, setIngredient] = useState(props.product.ingredient);
 const [retailPrice, setRetailPrice] = useState(props.product.retail_price);
 const [unit, setUnit] = useState(props.product.unit);
 const [base, setBase] = useState(props.product.base);
 const [displayOrder, setDisplayOrer] = useState(props.product.display_order);
 const [multiplier, setMultiplier] = useState(props.product.multiplier);
 const [priceMultiplier, setPriceMultiplier] = useState(props.product.price_multiplier);
 const [category, setCategory] = useState(props.product.category);
 const [imgId, setImgId] = useState(props.product.product_img_id);

 async function saveProduct() {
  let prodInfo = {
   id: product.id,
   category: category,
   display_order: displayOrder,
   type: type,
   product_name: productName,
   company: company,
   nick_name: nickName,
   retail_price: retailPrice,
   base: base,
   unit: unit,
   multiplier: multiplier,
   price_multiplier: priceMultiplier,
   product_img_id: imgId,
   product_img_url: ""
  }
  await updateProductInfoDB(prodInfo);
 }

 return (
  <Card style={{ margin: "10px" }}>
   <CardHeader>
    {product.product_name}
   </CardHeader>
   <CardBody>
    <InputGroup size="sm" style={{ marginTop: "4px" }}>
     <InputGroupText style={{ width: "30%" }}>
      ID
     </InputGroupText>
     <Input value={productId} type="text" onChange={(e) => {
      setProductId(e.target.value);
     }} />
    </InputGroup>
    <InputGroup size="sm" style={{ marginTop: "4px" }}>
     <InputGroupText style={{ width: "30%" }}>
      display_order
     </InputGroupText>
     <Input value={displayOrder} type="text" onChange={(e) => {
      setDisplayOrer(e.target.value);
     }} />
    </InputGroup>

    <InputGroup size="sm" style={{ marginTop: "4px" }}>
     <InputGroupText style={{ width: "30%" }}>
      카테고리
     </InputGroupText>
     <Input value={category} type="text" onChange={(e) => {
      setCategory(e.target.value);
     }} />
    </InputGroup>

    <InputGroup size="sm" style={{ marginTop: "4px" }}>
     <InputGroupText style={{ width: "30%" }}>
      제품명
     </InputGroupText>
     <Input value={productName} type="text" onChange={(e) => {
      setProductName(e.target.value);
     }} />
    </InputGroup>

    <InputGroup size="sm" style={{ marginTop: "4px" }}>
     <InputGroupText style={{ width: "30%" }}>
      닉네임
     </InputGroupText>
     <Input value={nickName} type="text" onChange={(e) => {
      setNickName(e.target.value);
     }} />
    </InputGroup>

    <InputGroup size="sm" style={{ marginTop: "4px" }}>
     <InputGroupText style={{ width: "30%" }}>
      제약회사
     </InputGroupText>
     <Input value={company} type="text" onChange={(e) => {
      setCompany(e.target.value);
     }} />
    </InputGroup>

    <InputGroup size="sm" style={{ marginTop: "4px" }}>
     <InputGroupText style={{ width: "30%" }}>
      성분명
     </InputGroupText>
     <Input value={ingredient} type="text" onChange={(e) => {
      setIngredient(e.target.value);
     }} />
    </InputGroup>

    <InputGroup size="sm" style={{ marginTop: "4px" }}>
     <InputGroupText style={{ width: "30%" }}>
      retail_price
     </InputGroupText>
     <Input value={retailPrice} type="text" onChange={(e) => {
      setRetailPrice(e.target.value);
     }} />
    </InputGroup>

    <InputGroup size="sm" style={{ marginTop: "4px" }}>
     <InputGroupText style={{ width: "30%" }}>
      base
     </InputGroupText>
     <Input value={base} type="text" onChange={(e) => {
      setBase(e.target.value);
     }} />
    </InputGroup>

    <InputGroup size="sm" style={{ marginTop: "4px" }}>
     <InputGroupText style={{ width: "30%" }}>
      unit
     </InputGroupText>
     <Input value={unit} type="text" onChange={(e) => {
      setUnit(e.target.value);
     }} />
    </InputGroup>

    <InputGroup size="sm" style={{ marginTop: "4px" }}>
     <InputGroupText style={{ width: "30%" }}>
      multiplier
     </InputGroupText>
     <Input value={multiplier} type="text" onChange={(e) => {
      setMultiplier(e.target.value);
     }} />
    </InputGroup>

    <InputGroup size="sm" style={{ marginTop: "4px" }}>
     <InputGroupText style={{ width: "30%" }}>
      price_multiplier
     </InputGroupText>
     <Input value={priceMultiplier} type="text" onChange={(e) => {
      setPriceMultiplier(e.target.value);
     }} />
    </InputGroup>


    <InputGroup size="sm" style={{ marginTop: "4px" }}>
     <InputGroupText style={{ width: "30%" }}>
      타입
     </InputGroupText>
     <Input value={type} type="select" onChange={(e) => {
      setType(e.target.value);
     }}>
      <option>
       COMMERCE
      </option>
      <option>
       SERVICE
      </option>
     </Input>
    </InputGroup>

   </CardBody>
   <CardFooter>
    <Row>
     <Col><Button color="primary" onClick={saveProduct}>저장하기</Button></Col>
    </Row>
   </CardFooter>
  </Card>
 )
}

export default ProductCard;
import { withAuthenticator } from "@aws-amplify/ui-react";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import { Button, Col, Container, Input, Row } from "reactstrap";
import { createMedicineInfo, getAllMedicineInfos } from "../../data/data";
import MedicineCard from "./MedicineCard";

function MedicinePage(props) {
 const [medicines, setMedicines] = useState([]);
 const [medicineId, setMedicineId] = useState("med_" + nanoid(6));

 useEffect(() => {
  async function wrapper() {
   let res = await getAllMedicineInfos();
   console.log(res);
   if (res) {
    setMedicines(res);
   }
  }
  wrapper();
 }, [])
 async function addMedicine() {
  let medicineInfo = {
   id: medicineId,
   type: "OTC_PILL",
   ingredient: "",
   company: "",
   product_name: "",
   nick_name: "",
   retail_price: 0,
   description: ""
  }
  let medicine = await createMedicineInfo(medicineInfo);
  console.log(medicine);
 }
 return (
  <>
   <Container style={{ paddingTop: "12px", marginBottom:"100px" }}>
    <Row>
     <Col>
      <Input type={"text"} placeholder="name" value={medicineId} onChange={(e) => { setMedicineId(e.target.value) }} />
     </Col>
     <Col>
      <Button onClick={addMedicine} color="primary">약 추가하기</Button>
     </Col>
    </Row>

    <Row>
     {medicines.map(med =>
      <Col md="6">
       <MedicineCard med={med} />
      </Col>
     )}
     <Col>
     </Col>
    </Row>
    
   </Container>
  </>
 )
}

export default withAuthenticator(MedicinePage);
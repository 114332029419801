import awsconfig from "./aws-exports";
import { Amplify } from "aws-amplify";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MedicinePage from "./views/MedicinePage/MedicinePage";
import MainPage from "./views/MainPage/MainPage";
import { withAuthenticator } from "@aws-amplify/ui-react";
import SurveyPage from "./views/SurveyPage/SurveyPage";

import '@aws-amplify/ui-react/styles.css';
import SchedulerPage from "./views/SchedulerPage/SchedulerPage";
import ProductPage from "./views/ProductPage/ProductPage";

Amplify.configure(awsconfig);


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/scheduler" element={<SchedulerPage />} />
          <Route path="/survey" element={<SurveyPage />} />
          <Route path="/medicine" element={<MedicinePage />} />
          <Route path="/product" element={<ProductPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default withAuthenticator(App);

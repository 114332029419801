import { useNavigate } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";

export default function MainPage(props) {
  const navigate = useNavigate();
  return (
    <>
      <br />
      <Container>
        <Row>
          <Col>
            <Button onClick={()=>{navigate("/survey")}}>Survey Data</Button>
          </Col>
          <Col>
            <Button onClick={()=>{navigate("/medicine")}}>Medicine Data</Button>
          </Col>
          <Col>
            <Button onClick={()=>{navigate("/product")}}>Product Data</Button>
          </Col>
          <Col>
            <Button onClick={()=>{navigate("/scheduler")}}>Scheduler Config</Button>
          </Col>
        </Row>
      </Container>
    </>
  )
}